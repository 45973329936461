<template>
  <div id="addAddress">
    <van-sticky>
      <van-nav-bar :title="title" left-arrow @click-left="onClickLeft" />
    </van-sticky>
    <div class="box p-lr16">
      <div class="flex-wrap m-b10">
        <div class="title2" style="letter-spacing: 3px;">收货人：</div>
        <input type="text" placeholder="请输入您的姓名" class="flex-con" v-model="username">
      </div>
      <div class="flex-wrap m-b10">
        <div class="title2">联系电话：</div>
        <input type="tel" placeholder="请输入您的电话" class="flex-con" v-model="phone" maxlength="11">
      </div>
      <div class="flex-wrap m-b10 relative" @click="getAreaData">
        <div class="title2">所在地区：</div>
        <input type="text" v-model="cascaderValue" placeholder="请选择您的所在地区" class="flex-con" readonly>
        <img :src="require('@/assets/bottom_arrow.png')" alt="" class="bottom_arrow">
      </div>
      <div class="flex-wrap">
        <div class="title2">详细地址：</div>
        <input type="text" placeholder="请输入您的详细地址" class="flex-con" v-model="address">
      </div>
      <!-- 调试所在地区时解开 -->
      <van-popup v-model="showArea" round position="bottom">
        <van-cascader title="请选择所在地区" :options="options" :field-names="fieldNames" @close="hideAreaPop" @finish="onFinishArea" />
      </van-popup>

    </div>
    <div class="btn_out">
      <div class="btn align-center" @click="submit">
        提交
      </div>
    </div>

  </div>
</template>
  <script>
import { getArea,insertAddress,updateAddress,addressDetail } from '../api'
export default {
  data() {
    return {
      showArea: false,
      options: [],
      cascaderValue: '',
      addr_province: '',
      addr_city: '',
      addr_district: '',
      fieldNames: {
        text: 'district',
        value: 'district_id',
        children: 'items',
      },
      username:'',
      phone:'',
      address:'',
      addr_id: 0,
      title:'新增收货地址'
    }
  },
  mounted() {
    this.addr_id = this.$route.params.addr_id;
    if (this.addr_id != 0) {
      this.title="修改收货地址"
      this.initData();
    }
  },
  methods: {
    submit(){
      if (this.username == '') {
        this.$toast('请输入姓名')
        return;
      }
      if (this.phone == '') {
        this.$toast('请输入手机号')
        return;
      }
      if (!/^1[3456789]\d{9}$/.test(this.phone)) {
        this.$toast('请输入正确的手机号')
        return;
      }
      if (this.addr_province == '') {
        this.$toast('请选择省市区')
        return;
      }
      if (this.address == '') {
        this.$toast('请填写详细地址')
        return;
      }
      if(this.addr_id == 0) {
        insertAddress({
          sheng:this.addr_province,
          shi:this.addr_city,
          qu:this.addr_district,
          username:this.username,
          phone:this.phone,
          address:this.address,
        }).then((data) => {
          if (data.status == 200) {
            this.$toast('添加地址成功')
            this.$router.go(-1);
          }
        })
      } else {
        updateAddress({
          id:this.addr_id,
          sheng:this.addr_province,
          shi:this.addr_city,
          qu:this.addr_district,
          username:this.username,
          phone:this.phone,
          address:this.address,
        }).then((data) => {
          if (data.status == 200) {
            this.$toast('修改地址成功')
            this.$router.go(-1);
          }
        })
      }
    },
    initData(){
      addressDetail({
        addr_id:this.addr_id,
        type:2
      }).then((data) => {
        if (data.status == 200) {
          this.username =data.msg.addr_username;
          this.phone =data.msg.addr_phone;
          this.address=data.msg.addr_address;
          this.cascaderValue =data.msg.sheng+data.msg.shi+data.msg.qu;
          this.addr_province = data.msg.addr_sheng;
          this.addr_city = data.msg.addr_qu;
          this.addr_district = data.msg.addr_qu;
        }
      })
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    getAreaData() {
      getArea({}).then((data) => {
        if (data.status == 200) {
          this.options = data.data;
          this.showArea = true;
        }
      })
    },
    onFinishArea({ selectedOptions, tabIndex }) {
      this.showArea = false;
      var areaName = selectedOptions.map((option) => option.district).join('');
      var areaNameId = selectedOptions.map((option) => option.district_id);
      this.cascaderValue = areaName;
      this.addr_province = areaNameId[0];
      this.addr_city = areaNameId[1];
      this.addr_district = areaNameId[2];
    },
    hideAreaPop() {
      this.showArea = false;
    },
  },
}
  </script>
  
  <style scoped>
::v-deep .van-nav-bar__left {
  width: 24px;
  height: 44px;
  background: url("@/assets/left_arrow.png") no-repeat center center;
  background-size: 24px 24px;
}
.box {
  padding-top: 16px;
}
.btn_out {
  position: fixed;
  width: 100%;
  bottom: 20px;
  height: 40px;
  padding: 0 16px;
  box-sizing: border-box;
}

.btn {
  height: 40px;
  background: #333333;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
  letter-spacing: 2px;
}

.title2 {
  font-size: 14px;
  color: #333333;
  line-height: 32px;
  width: 72px;
}
input[type="text"],input[type="tel"] {
  border: none;
  border-radius: 4px;
  padding: 0 16px;
  color: 333;
}

input[type="text"]::-webkit-input-placeholder,input[type="tel"]::-webkit-input-placeholder {
  font-size: 14px;
  font-weight: 400;
  color: #aaaaaa;
}

#addAddress {
  background: url("@/assets/bg.png") repeat-y center top;
  background-size: 100% 100%;
  height: 100vh;
}
.bottom_arrow {
  position: absolute;
  width: 18px;
  height: 18px;
  right: 16px;
  top: 7px;
}
</style>
  