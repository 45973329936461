<template>
  <div id="user">
    <div class="top flex-wrap">
      <div class="flex-con"></div>
      <img :src="require('@/assets/logo.png')" class="logo  hidden">
      <div>设备保修</div>
      <div class="flex-con"></div>
    </div>
    <img :src="user_image" class="avatar" @click="fnOther(1)">
    <div class="name">{{user_username||''}}</div>
    <div class="box">
      <div class="card">
        <div class="one flex-wrap" @click="fnOrder()">
          <div class="flex-con">订单列表</div>
          <div class="info">全部订单</div>
          <img :src="require('@/assets/right_arrow.png')" class="arrow">
        </div>
        <div class="flex-wrap" style="padding-bottom:22px;">
          <div class="flex-con" @click="fnOrder(1)">
            <img :src="require('@/assets/icon1.png')" class="icon">
            <div class="status">待审核</div>
          </div>
          <div class="flex-con" @click="fnOrder(2)">
            <img :src="require('@/assets/icon2.png')" class="icon">
            <div class="status">已发货</div>
          </div>
          <div class="flex-con" @click="fnOrder(3)">
            <img :src="require('@/assets/icon3.png')" class="icon">
            <div class="status">维修中</div>
          </div>
          <div class="flex-con" @click="fnOrder(4)">
            <img :src="require('@/assets/icon4.png')" class="icon">
            <div class="status">已回寄</div>
          </div>
          <div class="flex-con" @click="fnOrder(5)">
            <img :src="require('@/assets/icon5.png')" class="icon">
            <div class="status">已完成</div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="one flex-wrap">
          <div class="flex-con">其他功能</div>
        </div>
        <div class="bottom flex-wrap"  @click="fnOther(2)">
          <img :src="require('@/assets/icon6.png')" class="local">
          <div class="flex-con">
            收货地址
          </div>
          <img :src="require('@/assets/right_arrow.png')" class="arrow">
        </div>
      </div>
    </div>
    <NavBar></NavBar>
  </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import { userInfo } from '../api'
export default {
  components: { NavBar },
  data() {
    return {
      user_image:'',
      user_username:'',
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    initData() {
      userInfo({}).then((data) => {
        if (data.status == 200) {
          this.user_image =data.msg.user_image|| require('@/assets/tou.png');
          this.user_username =data.msg.user_username;
        }
      })
    },
    fnOrder(index) {
      var active;
      if(index==1){
        active=1;
      }else if(index==2){
        active=4;
      }else if(index==3){
        active=5;
      }else if(index==4){
        active=6;
      }else if(index==5){
        active=7;
      }else{
        active=0;
      }
      this.$router.push({ name: 'Order', params: { active: active } })
    },
    fnOther(i) {
      console.log(i)
      switch (i) {
        case 1:
          this.$router.push({ name: 'my', params: {} })
          break
        case 2:
          this.$router.push({ name: 'addressList', params: {} })
          break
        default:
          break
      }
    },
  },
}
</script>

<style scoped>
#user {
  height: 100%;
  background: url('@/assets/bg.png') repeat-y center top;
  background-size: 100% 100%;
}
.top{
  height:44px;
  align-items: center;
  margin-bottom: 32px;
}
.logo{
  width: 40px;
  height: 40px;
  margin-right: 8px;
}
.avatar{
  width: 81px;
  height: 81px;
  margin: auto;
  display: block;
  border-radius: 50%;
}
.name{
  font-size: 20px;
  font-weight: 500;
  color: #333333;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 17px;
}
.box{
  padding: 0 14px;
  box-sizing: border-box;
}
.card{
  background: #FFFFFF;
  box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.1);
  border-radius: 8px;
  margin-bottom: 12px;
}
.one{
  height: 58px;
  padding: 0 14px;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 500;
  color: #333333;
  align-items:center;
}
.info{
  font-size: 12px;
  font-weight: 400;
  color: #777777;
}
.arrow{
  width:18px;
  heigth:18px;
}
.icon{
  width:24px;
  heigth:24px;
  display: block;
  margin:0 auto 6px;
}
.status{
  font-size: 12px;
  font-weight: 400;
  color: #333333;
  text-align: center;
}
.local{
  width: 22px;
  height:22px;
  margin-right: 6px;
}
.bottom{
  font-size: 12px;
  font-weight: 400;
  color: #333333;
  align-items: center;
  padding: 0 15px 13px;
  box-sizing: border-box;
}
</style>
