<template>
  <div id="my">
    <van-sticky>
      <van-nav-bar title="个人资料" left-arrow @click-left="onClickLeft" />
    </van-sticky>
    <van-uploader :preview-full-image="false" :max-count="1"
                    :after-read="(file) => OnAfterRead(file, 1)" :before-read="onBeforeRead" preview-size="90">
      <div class="kuang">
          <img :src="user_image" class="avatar">
          <img :src="require('@/assets/camera.png')" class="camera">
      </div>
  </van-uploader>
    <div class="box p-lr16">
      <div class="flex-wrap m-b10">
        <div class="title2" style="letter-spacing: 3px;">用户名：</div>
        <input type="text" placeholder="请输入您的姓名" class="flex-con" v-model="user_username">
      </div>
      <div class="flex-wrap m-b10">
        <div class="title2">手机号：</div>
        <input type="text" placeholder="请输入您的手机号" v-model="user_phone" class="flex-con" readonly>
      </div>
      <div class="flex-wrap m-b10 relative"  @click="fnPwd">
        <div class="title2">登录密码：</div>
        <input type="text" placeholder="更换密码" class="flex-con" readonly>
        <img :src="require('@/assets/right_arrow.png')" alt="" class="right_arrow">
      </div>
    </div>
    <div class="btn_out">
      <div class="btn align-center" @click="fnSave">
        保存
      </div>
    </div>

  </div>
</template>
  <script>
  import Compressor from 'compressorjs';
import { userInfo,updateUser,uploadFile  } from '../api'
import { Toast } from 'vant';
import { put } from '@/utils/ali-oss'
export default {
  data() {
    return {
      user_image:'',
      user_username:'',
      user_phone:'',
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    fnSave(){
      updateUser({
        username:this.user_username,
        user_image:this.user_image,
      }).then((data) => {
        if (data.status == 200) {
          this.$toast('修改成功')
        }
      })
    },
    initData() {
      userInfo({}).then((data) => {
        if (data.status == 200) {
          this.user_image =data.msg.user_image|| require('@/assets/tou.png');
          this.user_username =data.msg.user_username;
          this.user_phone =data.msg.user_phone;
        }
      })
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    fnPwd(){
      this.$router.push({ path: 'changePwd', query: {} })
    },
    OnAfterRead(e, type) {
      let _self = this;
      Toast.loading({
        duration: 100000, // 持续展示 toast
        forbidClick: true,
        message: '上传中',
      });
      let files = e.file
      // alert(files.name + '||' + files.size + '||' + files.type);
      let objName = new Date().getTime()+files.name.split('.')[1]


      put(`${objName}`, e.file).then(res => {
        // alert('res||' + res.url);
        console.log(res)
        Toast.clear();
        if (res.res.statusCode === 200) {
           this.user_image = res.url
        } else {
          this.$toast('上传失败')
          // this.$message.error('上传失败')
        }
      }).catch((err) => {
        Toast.clear();
        this.$toast('上传失败')
        console.log(err)
        // alert('上传失败',JSON.stringify(err));
      })

    },
    onBeforeRead(e) {
      // alert(e.name+'|'+e.type+'|'+e.size)
      // return
      return new Promise((resolve, reject) => {
        new Compressor(e, {
          //压缩质量, 0-1
          quality: 0.6,
          //转换的类型，默认为 image/png
          convertTypes: ['image/jpeg', 'image/png'],
          //需要压缩的起始大小，默认2M, 2 * 1000 * 1000
          convertSize: 2000000,
          //压缩成功后处理
          success(result) {
            let file = new File([result], e.name, { type: e.type })
            // console.log('408 ' + file);
            resolve(file);
          },
          error(err) {
            // alert('682||' + JSON.stringify(err))
            // console.log(err.message);
            reject()
          },
        });
      });
    },
  },
}
  </script>
  
  <style scoped>
::v-deep .van-nav-bar__left {
  width: 24px;
  height: 44px;
  background: url("@/assets/left_arrow.png") no-repeat center center;
  background-size: 24px 24px;
}
.box {
  padding-top: 16px;
}
.btn_out {
  position: fixed;
  width: 100%;
  bottom: 20px;
  height: 40px;
  padding: 0 16px;
  box-sizing: border-box;
}

.btn {
  height: 40px;
  background: #333333;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
  letter-spacing: 2px;
}

.title2 {
  font-size: 14px;
  color: #333333;
  line-height: 32px;
  width: 72px;
}
input[type="text"] {
  border: none;
  border-radius: 4px;
  padding: 0 16px;
  color: 333;
}

input[type="text"]::-webkit-input-placeholder {
  font-size: 14px;
  font-weight: 400;
  color: #aaaaaa;
}

#my {
  background: url("@/assets/bg.png") repeat-y center top;
  background-size: 100% 100%;
  height: 100vh;
}
.right_arrow {
  position: absolute;
  width: 18px;
  height: 18px;
  right: 16px;
  top: 7px;
}
.kuang{
    position: relative;
    width: 108px;
    height: 108px;
    margin:26px auto;
}
.avatar{
    width: 108px;
    height: 108px;
    border-radius: 50%;
}
.camera{
    position:absolute;
    right: 0;
    bottom: 0;
    width: 32px;
    height: 32px;
}
::v-deep .van-uploader{
  width:100%;
}
::v-deep .van-uploader__input-wrapper{
  width: 100%;
}
</style>
  