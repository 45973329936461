<template>
  <div id="afterSales">
    <!-- <van-sticky> -->
    <van-nav-bar :title="title" left-arrow @click-left="onClickLeft" />
    <!-- </van-sticky> -->
    <div class="p-lr16">
      <div v-if="order.order_status==3">
        <div class="title1 p-tb16">拒绝理由</div>
        <div class="refuse"> {{order.order_refuse_reason}}</div>
      </div>
      <div v-if="order.order_jiance_status==3">
        <div class="title1 p-tb16">检测未通过原因</div>
        <div class="refuse"> {{order.order_jiance_remark}}</div>
      </div>
      <div v-if="order.order_status==4">
        <div class="align-between title1 p-tb16">
          <div>返厂快递信息</div>
          <div class="align-center" @click="fnWl(1)">
            <div class="color333-14">查看物流</div>
            <img :src="require('@/assets/right_arrow.png')" alt="" class="right_arrow">
          </div>
        </div>
        <div class="flex-wrap m-b10">
          <div class="title2">快递单号：</div>
          <input type="text" placeholder="无" class="flex-con"  v-model="order.order_user_express_code">
        </div>
        <div class="flex-wrap m-b10">
          <div class="title2">快递公司：</div>
          <input type="text" placeholder="无" class="flex-con"  v-model="order.order_user_express_company">
        </div>
        <div class="flex-wrap">
          <div class="title2">回邮地址：</div>
          <input type="text" placeholder="无" class="flex-con"  v-model="order.order_company_address">
        </div>
      </div>

      <div v-if="order.order_status==6 && order.order_company_express_type==1">
        <div class="align-between title1 p-tb16">
          <div>回寄快递信息</div>
          <div class="align-center"  @click="fnWl(2)">
            <div class="color333-14">查看物流</div>
            <img :src="require('@/assets/right_arrow.png')" alt="" class="right_arrow">
          </div>
        </div>
        <div class="flex-wrap m-b10">
          <div class="title2">快递单号：</div>
          <input type="text" placeholder="无" class="flex-con" v-model="order.order_company_express_code">
        </div>
        <div class="flex-wrap m-b10">
          <div class="title2">快递公司：</div>
          <input type="text" placeholder="无" class="flex-con" v-model="order.order_company_express_company">
        </div>
        <div class="flex-wrap">
          <div class="title2">回邮地址：</div>
          <input type="text" placeholder="无" class="flex-con" v-model="order.order_user_address">
        </div>
        <div v-if="order.order_nj_user2.order_company_express_code!=''">
          <div class="align-between title1 p-tb16">
            <div>回寄快递信息2</div>
            <div class="align-center"  @click="fnWl(3)">
              <div class="color333-14">查看物流</div>
              <img :src="require('@/assets/right_arrow.png')" alt="" class="right_arrow">
            </div>
          </div>
          <div class="flex-wrap m-b10">
            <div class="title2">快递单号：</div>
            <input type="text" placeholder="无" class="flex-con" v-model="order.order_nj_user2.order_company_express_code">
          </div>
          <div class="flex-wrap m-b10">
            <div class="title2">快递公司：</div>
            <input type="text" placeholder="无" class="flex-con" v-model="order.order_nj_user2.order_company_express_company">
          </div>
          <div class="flex-wrap">
            <div class="title2">回邮地址：</div>
            <input type="text" placeholder="无" class="flex-con" v-model="order.order_nj_user2.order_user_address">
          </div>
          </div>
          <div v-if="order.order_nj_user3.order_company_express_code!=''">
          <div class="align-between title1 p-tb16">
            <div>回寄快递信息3</div>
            <div class="align-center"  @click="fnWl(4)">
              <div class="color333-14">查看物流</div>
              <img :src="require('@/assets/right_arrow.png')" alt="" class="right_arrow">
            </div>
          </div>
          <div class="flex-wrap m-b10">
            <div class="title2">快递单号：</div>
            <input type="text" placeholder="无" class="flex-con" v-model="order.order_nj_user3.order_company_express_code">
          </div>
          <div class="flex-wrap m-b10">
            <div class="title2">快递公司：</div>
            <input type="text" placeholder="无" class="flex-con" v-model="order.order_nj_user3.order_company_express_company">
          </div>
          <div class="flex-wrap">
            <div class="title2">回邮地址：</div>
            <input type="text" placeholder="无" class="flex-con" v-model="order.order_nj_user3.order_user_address">
          </div>
          </div>
      </div>

      <div class="title1 p-tb16">个人信息</div>
      <div class="flex-wrap m-b10">
        <div class="title2" style="letter-spacing: 3px;">报修人：</div>
        <input type="text" placeholder="请输入您的姓名" class="flex-con" v-model="order.order_username" readonly>
      </div>
      <div class="flex-wrap m-b10">
        <div class="title2">联系电话：</div>
        <input type="text" placeholder="请输入您的电话" class="flex-con" v-model="order.order_phone" readonly>
      </div>
      <div class="flex-wrap m-b10">
        <div class="title2">单位名称：</div>
        <input type="text" placeholder="请输入您的单位名称" class="flex-con" v-model="order.order_company" readonly>
      </div>
      <div class="flex-wrap">
        <div class="title2">单位地址：</div>
        <input type="text" placeholder="请输入您的详细地址" class="flex-con" v-model="order.order_address_str" readonly>
      </div>

      <div class="title1 p-tb16">维修产品</div>
      <div class="product" v-for="(item, index) in orderInfo" :key="'orderInfo' + index">
        <div class="title3 align-between">
          <div>
            产品系列
          </div>
        </div>
        <div class="title4">
          {{ item.xl_name || '' }}
        </div>
        <div class="title3">
          详细型号
        </div>
        <div class="title4">
          {{ item.xh_name || '' }}
        </div>
        <div class="flex-wrap">
          <div class="flex-con">
            <div class="title3">
              序列号
            </div>
            <div class="title4">
              {{ item.oi_xulie || '' }}
            </div>
          </div>
          <div class="flex-con">
            <div class="title3">
              故障类型
            </div>
            <div class="title4">
              {{ item.gz_name || '' }}
            </div>
          </div>
        </div>
        <div class="title3">
          故障描述
        </div>
        <div class="title4">
          {{ item.oi_desc || '' }}
        </div>
        <div class="title3">
          问题照片
        </div>
        <div class="align-start problem_img" style="flex-wrap: wrap;">
          <img :src="item.oi_front_image" alt="" class="img1" @click="fnBig1(index, 0)">
            <img :src="item.oi_side_image" alt="" class="img1" @click="fnBig1(index, 1)">
              <img :src="item.oi_mp_image" alt="" class="img1" @click="fnBig1(index, 2)">
        </div>
        <!-- <van-image-preview v-model="show1" :images="item.imgThree">
        </van-image-preview> -->
        <div class="title3">
          其他
        </div>
        <!-- v-for="(item, index) in images1" :key="'img1_' + index" -->
        <div class="align-start problem_img" style="flex-wrap: wrap;">
          <div class="img1" style="position: relative;" @click="fnVideo(item.oi_other_video)" v-show="item.oi_other_video">
            <img :src="item.oi_other_video + '?x-oss-process=video/snapshot,t_1000,f_jpg,w_0,h_0,m_fast,ar_auto'" alt="" style="object-fit: cover;width:100%;height:100%;">
              <img :src="require('@/assets/play.png')" alt="" class="play">
          </div>
          <img v-for="(item1, index) in item.oi_other_image" :key="'img2_' + index" :src="item1" alt="" class="img1" @click="fnBig2(index,item.oi_other_image )">
        </div>
        <!-- <van-image-preview v-model="show2" :images="item.oi_other_image">
        </van-image-preview> -->
        <div v-if="item.oi_wx_report_url!=''">
          <div class="title3">
            故障报告
          </div>
          <div class="doc">
            <a target='_black'  :href='item.oi_wx_report_url'>
            <div class="align-start" style="padding-bottom: 6px;">
              <img :src="require('@/assets/wd/pdf.png')" style="width: 32px; height: 32px" class="m-r12" />
              <div class="color333-14">{{item.oi_wx_report_name || '' }}</div>
            </div>
            <div class="align-between coloraaa-12 hidden" style="padding-top: 7px;">
              <div>0.95M</div>
              <div>2023.01.02</div>
            </div>
          </a>
            
          </div>
        </div>
      </div>

    </div>

    <div class="btn_out"  v-if="order.order_status==1">
      <div class="btn align-center" @click="showPopup(1)">
        取消订单
      </div>
    </div>
    <div class="btn_out" v-if="order.order_status==2" >
      <div class="btn align-center"  @click="fnSend">
        去发货
      </div>
    </div>
    <div class="btn_out"  v-if="order.order_status==6">
      <div class="btn align-center" @click="showPopup(2)">
        确认收货
      </div>
    </div>

    <van-overlay :show="showVideo" @click="fnStop">
      <video style="width:100%;height:100%;" @click="showVideo = false" :src="bigVideo" autoplay="true" id="video111" preload>
      </video>
    </van-overlay>
    <van-popup v-model="show" round>
      <div class="tipBox">
        <div style="margin-bottom: 20px">
          确认取消订单？
        </div>
        <div class="align-between">
          <div class="tipBtn1 align-center" @click="fnCancel(1)">确认</div>
          <div class="tipBtn2 align-center" @click="fnCancel(2)">取消</div>
        </div>
      </div>
    </van-popup>
    <van-popup v-model="show1" round>
      <div class="tipBox">
        <div style="margin-bottom: 20px">
          确认收货？
        </div>
        <div class="align-between">
          <div class="tipBtn1 align-center" @click="fnReceipt(1)">确认</div>
          <div class="tipBtn2 align-center" @click="fnReceipt(2)">取消</div>
        </div>
      </div>
    </van-popup>

  </div>
</template>
<script>
import { orderDetail,cancelOrder,sendOrder,overOrder, } from '../api'
import { ImagePreview } from 'vant';
export default {
  data() {
    return {
      id: '',
      order: {},
      orderInfo: [],
      showVideo: false,
      bigVideo: '',
      title: '售后申请',
      status:'',
      show: false,
      show1: false,  
      order_jiance_status:''   
    }
  },
  mounted() {
    this.id = this.$route.params.id;
    this.initData();
  },
  methods: {
    initData() {
      orderDetail({
        id: this.id
      }).then((data) => {
        if (data.status == 200) {
          this.orderInfo = data.orderInfo
          this.order = data.order
          this.status=data.order.order_status
          this.order_jiance_status=data.order.order_jiance_status;
          if(this.status==1){
            this.title='待审核'
          }else if(this.status==2){

            this.title='审核通过'
            
          }else if(this.status==3){
            this.title='审核拒绝'
          }else if(this.status==4){
            this.title='返厂中'
          }else if(this.status==5){
           
            if(this.order_jiance_status==1){
              this.title='待检测'
            }else if(this.order_jiance_status==2){
              this.title='检测中'
            }else{
              this.title='检测未通过'
            }
            
          }else if(this.status==6){
            if(this.order_jiance_status==3){
              this.title='已寄回-检测未通过'
            }else{
              this.title='已寄回'
            }
          }else if(this.status==7){
            if(this.order_jiance_status==3){
              this.title='已完成-检测未通过'
            }else{
              this.title='已完成'
            }
          }else{
            this.title='已取消'
          }
        }
      })
    },
    fnVideo(video) {
      this.bigVideo = video
      this.showVideo = true
      let video1 = document.getElementById('video111')
      setTimeout(() => {
        video1.play()
      }, 200);
      
    },
    fnStop(){
      let video1 = document.getElementById('video111')
      video1.pause()
      this.showVideo = false
    },
    fnBig1(index1, index2) {
      let arr = [];
      arr[0] = this.orderInfo[index1].oi_front_image;
      arr[1] = this.orderInfo[index1].oi_side_image;
      arr[2] = this.orderInfo[index1].oi_mp_image;
      ImagePreview({
        images: arr,
        startPosition: index2,
      });
    },
    fnBig2(index2, arr) {
      ImagePreview({
        images: arr,
        startPosition: index2,
      });
    },
    fnScan() {
      this.$router.push({
        name: 'qrcode'
      });
    },
    showPopup(type) {
      if (type == 1) {
        this.show = true
      } else {
        this.show1 = true
      }
    },
    fnCancel(type) {
      if (type == 1) {
        cancelOrder({
          id: this.id
        }).then((data) => {
          if (data.status == 200) {
            this.show = false;
            this.refreshing = true;
            this.page = 1;
            this.initData();
          }
        })
      } else {
        this.show = false
      }
    },
    fnReceipt(type) {
      if (type == 1) {
        overOrder({
          id: this.id
        }).then((data) => {
          if (data.status == 200) {
            this.show1 = false;
            this.refreshing = true;
            this.page = 1;
            this.initData();
          }
        })
      } else {
        this.show1 = false
      }
    },
    fnSend(){
      this.$router.push({name:"wuliu",params:{id:this.id}})
    },
    fnWl(type){
      this.$router.push({name:"wlXq",params:{id:this.id,type:type}})
    },
    onClickLeft() {
      this.$router.go(-1);
    },
  },
}
</script>

<style scoped>
.otherImg,
.otherImg > .van-uploader__wrapper,
.otherImg > .van-uploader__wrapper > .van-uploader__preview {
  width: 100%;
}

::v-deep .otherImg .van-uploader__wrapper > .van-uploader__preview {
  margin-bottom: 10px;
  margin-right: calc((100% - 270px) / 2);
}

::v-deep
  .otherImg
  .van-uploader__wrapper
  > .van-uploader__preview:nth-child(3n) {
  margin-right: 0;
}

::v-deep .three .van-uploader__preview {
  margin: 0;
}

::v-deep .other_video1 .van-uploader__preview {
  margin-right: 0;
}

.preview-cover {
  position: absolute;
  bottom: 0;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  /* padding: 4px; */
  /* color: #fff; */
  /* font-size: 12px;
  text-align: center;
  background: rgba(0, 0, 0, 0.3); */
}

.van-uploader__preview-cover .other_video {
  width: 100%;
  height: 100%;
  position: relative;
}

.van-uploader__preview-cover .play {
  position: absolute;
  width: 20px;
  height: 20px;
  margin: auto;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}
.play {
  position: absolute;
  width: 20px;
  height: 20px;
  margin: auto;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}
.doc {
  border-radius: 4px;
  border: 1px solid #f1f1f1;
  padding: 10px 12px;
  margin-bottom: 16px;
}

.refuse {
  font-size: 14px;
  font-weight: 400;
  color: #aaaaaa;
  line-height: 20px;
  background: #ffffff;
  border-radius: 4px;
  padding: 6px 16px;
}

.img_bg {
  width: 90px;
  height: 90px;
  background: url("@/assets/img_bg.png") no-repeat center center;
  background-size: 100% 100%;
}

.img_bg .img_txt {
  position: absolute;
  bottom: 10px;
  width: 100%;
  text-align: center;
}

textarea {
  border-radius: 2px;
  border: 1px solid #f7f7f7;
  color: 333;
  box-sizing: border-box;
  padding: 6px 16px;
  font-size: 14px;
}

.block input[type="text"] {
  border: 1px solid #f7f7f7;
  border-radius: 2px;
  padding: 0 16px;
  color: 333;
  box-sizing: border-box;
}

.tan_title1 {
  font-weight: 500;
  margin-bottom: 10px;
  text-align: center;
}

.close_tan {
  width: 18px;
  height: 18px;
  position: absolute;
  right: 16px;
  top: 16px;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.block {
  background: #ffffff;
  border-radius: 4px;
  font-size: 14px;
  color: #333333;
  line-height: 20px;
  padding: 16px;
  position: relative;
}

.img1 {
  width: 90px;
  height: 90px;
  object-fit: cover;
}

.problem_img {
  margin-bottom: 16px;
}

.problem_img > .img1:nth-child(3n + 2) {
  display: block;
  margin: 0 calc((100% - 270px) / 2);
}

.close {
  width: 18px;
  height: 18px;
}

.title4 {
  font-size: 14px;
  color: #333333;
  line-height: 20px;
  margin-bottom: 16px;
}

.title3 {
  font-size: 14px;
  font-weight: 500;
  color: #333333;
  line-height: 20px;
  margin-bottom: 6px;
}

.product {
  background: #ffffff;
  border-radius: 4px;
  padding: 12px 12px 2px;
}

.btn_out {
  position: fixed;
  width: 100%;
  bottom: 20px;
  height: 40px;
  padding: 0 16px;
  box-sizing: border-box;
}

.btn {
  height: 40px;
  background: #333333;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
  letter-spacing: 2px;
}

.circle {
  position: fixed;
  top: 54vh;
  right: 6px;
  width: 100px;
  height: 100px;
  background: url("@/assets/circle.png") no-repeat center center;
  background-size: 100% 100%;
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
  line-height: 25px;
}

.xian1 {
  background-color: #fff;
  height: 1px;
}

.wu {
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  line-height: 22px;
  letter-spacing: 2px;
  text-align: center;
  margin-top: 19px;
}

.bottom_arrow {
  position: absolute;
  width: 18px;
  height: 18px;
  right: 16px;
  top: 7px;
}

.right_arrow {
  width: 18px;
  height: 18px;
  margin-left: 8px;
}

input[type="text"] {
  border: none;
  border-radius: 4px;
  padding: 0 16px;
  color: 333;
  font-size: 14px;
}

input[type="text"]::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  font-size: 14px;
  font-weight: 400;
  color: #aaaaaa;
}

.title2 {
  font-size: 14px;
  color: #333333;
  line-height: 32px;
  width: 72px;
}

#afterSales {
  height: calc(100% - 80px);
  background: url("@/assets/bg.png") repeat-y center top;
  background-size: 100% 100%;
  padding-bottom: 80px;
  overflow-y: scroll;
}

::v-deep .van-nav-bar__left {
  width: 24px;
  height: 44px;
  background: url("@/assets/left_arrow.png") no-repeat center center;
  background-size: 24px 24px;
}

.title1 {
  font-size: 16px;
  font-weight: 500;
  color: #333333;
  line-height: 22px;
}
.tipBox {
  width: 275px;
  height: 105px;
  background: #ffffff;
  border-radius: 4px;
  padding: 18px;
  background: #fff;
  text-align: center;
  box-sizing: border-box;
  font-size: 18px;
  font-weight: 500;
  color: #333333;
}

.tipBtn1 {
  width: 80px;
  height: 25px;
  border-radius: 4px;
  margin-left: 12px;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: 400;
  color: #777777;
}

.tipBtn2 {
  font-size: 16px;
  font-weight: 400;
  color: #333;
  width: 80px;
  height: 25px;
  border-radius: 4px;
  margin-left: 12px;
  box-sizing: border-box;
}
.product:not(:last-child) {
 margin-bottom: 12px;
}
</style>
