<template>
    <div>
        <van-nav-bar title="物流信息" left-arrow @click-left="onClickLeft" />
            <div class="align-start color333-14" style="padding: 16px; border-bottom: 1px solid #d8d8d8">
                <img :src="require('@/assets/kd/sf.png')" style="width: 32px; height: 32px" class="m-r12 hidden" />
                <div class="m-r12">{{msg.company}}</div>
                <div class="xian m-r12"></div>
                <div>{{msg.code}}</div>
            </div>
            <div v-if="status == 200" style="padding: 16px; border-bottom: 1px solid #d8d8d8">
                <div class="flex-wrap" v-for="(item, index) in wldata">
                    <img v-if="index == 0" :src="require('@/assets/wl_icon1.png')"
                        style="width: 18px; height: 18px" class="m-r10" />
                    <img v-else :src="require('@/assets/wl_icon2.png')" style="width: 18px; height: 18px" class="m-r10" />
                    <div class="flex-con" style="position: relative">
                        <div class="color333-14-bold">
                            {{ item.ftime }}
                        </div>
                        <div style="position: relative; padding: 16px 0 20px 0" class="color777-12">
                            {{ item.context }}
                            <div class="wlXian" v-if="index != wldata.length - 1"></div>
                        </div>
                    </div>
                </div>
            </div>
    </div>
</template>
  
<script>
import { orderWl } from '../api'
export default {
    data() {
        return {
            msg: '',
            wldata: '',
            status:0,
            id:'',
            type:''
        }
    },
    methods: {
        initData() {
            orderWl({
                id: this.id,
                type: this.type
            }).then((data) => {
                if (data.status == 200) {
                    this.msg = data.express;
                    this.wldata = data.msg;
                    this.status=data.status;
                }
            })
        },
        onClickLeft() {
            this.$router.go(-1)
        },
    },
    mounted() {
        this.id = this.$route.params.id;
        this.type = this.$route.params.type;
        this.initData();
    },
}
</script>
  
<style scoped>
.van-popup--center.van-popup--round {
    border-radius: 10px;
}

.xian {
    width: 1px;
    height: 15px;
    background: #333333;
    border-radius: 1px;
}

.wlXian {
    width: 0px;
    height: 100%;
    /* background: #d8d8d8; */
    position: absolute;
    left: -19px;
    top: 0;
    border-right: 1px dashed #d8d8d8;
}

::v-deep .van-nav-bar__left {
    width: 24px;
    height: 44px;
    background: url('@/assets/left_arrow.png') no-repeat center center;
    background-size: 24px 24px;
}</style>
  