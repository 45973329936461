<template>
  <van-tabbar
    v-model="active"
    active-color="#333333"
    inactive-color="#333333"
    fixed
  >
    <van-tabbar-item
      :to="item.name"
      @click="tabIndex(index)"
      v-for="(item, index) in tabbars"
      :key="'tabbar' + index"
    >
      <span>{{ item.title }}</span>
      <template #icon="props">
        <img :src="props.active ? item.active : item.normal" />
      </template>
    </van-tabbar-item>
  </van-tabbar>
</template>

<script>
export default {
  name: 'indexTab',
  data() {
    return {
      active: 0, //默认选中tab
      tabbars: [
        {
          name: 'main',
          title: '首页',
          normal: require('@/assets/nav11.png'),
          active: require('@/assets/nav1.png'),
        },
        {
          name: 'mark',
          title: '产品手册',
          normal: require('@/assets/nav33.png'),
          active: require('@/assets/nav3.png'),
        },
        {
          name: 'user',
          title: '我的',
          normal: require('@/assets/nav22.png'),
          active: require('@/assets/nav2.png'),
        },
      ],
    }
  },
  mounted() {
    // this.$bus.$on('changeTab', (val) => {
    //   sessionStorage.setItem('tabIndex', val)
    //   let index = sessionStorage.getItem('tabIndex')
    //   if (index) {
    //     this.tabIndex(index)
    //   }
    // })
    let index = sessionStorage.getItem('tabIndex')
    if (index) {
      this.tabIndex(index)
    }
  },
  methods: {
    tabIndex(index) {
      index = Number(index)
      this.active = index
      // 记录当前tab页
      sessionStorage.setItem('tabIndex', index)
      let path = this.tabbars[index].name
      this.$router.push({ path: path },()=>{})
    },
  },
}
</script>

<style>
.van-tabbar {
  border-top: 1px solid #f5f5f5;
}
.van-tabbar-item__icon img {
  width: 24px;
  height: 26px;
}
</style>
