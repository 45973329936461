<template>
  <div id="order">
      <van-nav-bar title="我的订单" left-arrow @click-left="onClickLeft" :border="false" :safe-area-inset-top="true" />
      <van-tabs v-model="activeName" title-activeName-color="#333333" title-inactiveName-color="#777777" line-height="0" @click="changeTab(activeName)">
        <van-tab v-for="item in sort" :title="item.name" :name="item.id"> </van-tab>
      </van-tabs>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
        <div class="box" style="padding:0 16px;box-sizing:border-box;">
          <div class="card" v-for="item in list" @click="fnXq(item.order_id)">
            <div class="one flex-wrap">
              <div class="flex-con">订单编号：{{item.order_no}}</div>
              <div class="status" v-if="item.order_status==1">待审核</div>
              <div class="status" v-else-if="item.order_status==2">
                审核通过
              </div>
              <div class="status" v-else-if="item.order_status==3">审核拒绝</div>
              <div class="status" v-else-if="item.order_status==4">返厂中</div>
              <div class="status" v-else-if="item.order_status==5">
                      <span v-if="item.order_jiance_status==1">待检测</span>
                      <span v-else-if="item.order_jiance_status==2">检测中</span>
                      <span v-else>检测未通过</span>
              </div>
              <div class="status" v-else-if="item.order_status==6">
                已寄回<span v-if="item.order_jiance_status==3">-检测未通过</span>
              </div>
              <div class="status" v-else-if="item.order_status==7">
                已完成<span v-if="item.order_jiance_status==3">-检测未通过</span>
              </div>
              <div class="status" v-else>已取消</div>
            </div>
            <div class="bottom">
              <div class="info flex-wrap">
                <div class="name">产品系列：</div>
                <div class="flex-con">{{item.device_name}}</div>
              </div>
              <div class="info flex-wrap">
                <div class="name">产品件数：</div>
                <div class="flex-con">{{item.order_number}}件</div>
              </div>
              <div class="info flex-wrap">
                <div class="name">提交时间：</div>
                <div class="flex-con">{{item.order_time}}</div>
              </div>
              <div class="flex-wrap">
                <div class="flex-con"></div>
                <div class="btn" v-if="item.order_status==1" @click.stop="showPopup(item.order_id,1)">取消订单</div>
                <div class="btn1" v-if="item.order_status==2"  @click.stop="fnSend(item.order_id)">去发货</div>
                <div class="btn hidden" v-if="item.order_status==6"  @click.stop="fnWl(item.order_id,item.order_status)">查看物流</div>
                <div class="btn1" v-if="item.order_status==6" @click.stop="showPopup(item.order_id,2)">确认收货</div>
              </div>
            </div>
          </div>

        </div>
      </van-list>
    </van-pull-refresh>
    <van-popup v-model="show" round>
      <div class="tipBox">
        <div style="margin-bottom: 20px">
          确认取消订单？
        </div>
        <div class="align-between">
          <div class="tipBtn1 align-center" @click="fnCancel(1)">确认</div>
          <div class="tipBtn2 align-center" @click="fnCancel(2)">取消</div>
        </div>
      </div>
    </van-popup>
    <van-popup v-model="show1" round>
      <div class="tipBox">
        <div style="margin-bottom: 20px">
          确认收货？
        </div>
        <div class="align-between">
          <div class="tipBtn1 align-center" @click="fnReceipt(1)">确认</div>
          <div class="tipBtn2 align-center" @click="fnReceipt(2)">取消</div>
        </div>
      </div>
    </van-popup>

  </div>
</template>
  
  <script>
import { orderList,cancelOrder,sendOrder,overOrder } from '../api'
export default {
  data() {
    return {
      show: false,
      show1: false,
      activeName: 0,
      page: 1,
      count: 0,
      list: [],
      loading: true,
      finished: false,
      refreshing: false,
      sort: [
        {
          name: '全部',
          id: 0,
        },
        {
          name: '待审核',
          id: 1,
        },
        {
          name: '返场中',
          id: 4,
        },
        {
          name: '维修中',
          id: 5,
        },
        {
          name: '已寄回',
          id: 6,
        },
        {
          name: '已完成',
          id: 7,
        },
      ],
      list: [],
      order_id: '',
    }
  },
  methods: {
    changeTab(num) {
      this.refreshing = true
      this.activeName = num
      this.page = 1
      this.initData()
    },
    initData() {
      orderList({
        status: this.activeName,
        page: this.page
      }).then((data) => {
        if (data.status == 200) {
          this.count = data.count;
          if (this.refreshing) {
            this.list = data.data
            this.refreshing = false
            if (this.list.length >= this.count) {
              this.finished = true
            }
            return
          }
          this.list = this.list.concat(data.data)
          this.loading = false
          if (this.list.length >= this.count) {
            this.finished = true
          }
        }
      })
    },
    fnXq(id) {
      this.$router.push({name:"orderDetails",params:{id:id}})
    },
    onLoad() {
      this.page++
      this.initData()
    },
    onRefresh() {
      this.finished = false
      this.loading = true
      this.page = 1
      this.initData()
    },
    showPopup(id, type) {
      if (type == 1) {
        this.show = true
      } else {
        this.show1 = true
      }
      this.order_id = id;
    },
    fnCancel(type) {
      if (type == 1) {
        cancelOrder({
          id: this.order_id
        }).then((data) => {
          if (data.status == 200) {
            this.show = false;
            this.refreshing = true;
            this.page = 1;
            this.initData();
          }
        })
      } else {
        this.show = false
      }
    },
    fnReceipt(type) {
      if (type == 1) {
        overOrder({
          id: this.order_id
        }).then((data) => {
          if (data.status == 200) {
            this.show1 = false;
            this.refreshing = true;
            this.page = 1;
            this.initData();
          }
        })
      } else {
        this.show1 = false
      }
    },
    fnSend(id){
      this.$router.push({name:"wuliu",params:{id:id}})
    },
    fnWl(id,status){
      var type;
      if(status==4){
        type=1;
      }else if(status==6){
        type=2;
      }
      this.$router.push({name:"wlXq",params:{id:id,type:type}})
    },
    onClickLeft() {
      this.$router.go(-1)
    },
  },
  mounted() {
    this.activeName = Number(this.$route.params.active)
    this.initData();
  },
}
  </script>
  
  <style scoped>
::v-deep .van-nav-bar__left {
  width: 24px;
  height: 44px;
  background: url("@/assets/left_arrow.png") no-repeat center center;
  background-size: 24px 24px;
}
#order {
  height: 100%;
  background: url("@/assets/bg.png") repeat-y center top;
  background-size: 100% 100%;
}
.van-list {
  min-height: calc(100vh - 88px);
}
::v-deep .van-tabs__nav {
  background-color: transparent;
}
.card {
  background: #ffffff;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  margin-bottom: 12px;
}
.one {
  height: 38px;
  align-items: center;
  background: #f5f5f5;
  padding: 0 12px;
}
.bottom {
  padding: 6px 12px 10px;
}
.info {
  margin-bottom: 6px;
}
.name {
  font-size: 14px;
  font-weight: 400;
  color: #777777;
}
.btn {
  width: 80px;
  text-align: center;
  height: 27px;
  line-height: 27px;
  border-radius: 14px;
  border: 1px solid #979797;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  margin-left: 12px;
}
.btn1 {
  width: 80px;
  height: 27px;
  text-align: center;
  background: #333333;
  border-radius: 14px;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  line-height: 27px;
  margin-left: 12px;
}
.tipBox {
  width: 275px;
  height: 105px;
  background: #ffffff;
  border-radius: 4px;
  padding: 18px;
  background: #fff;
  text-align: center;
  box-sizing: border-box;
  font-size: 18px;
  font-weight: 500;
  color: #333333;
}

.tipBtn1 {
  width: 80px;
  height: 25px;
  border-radius: 4px;
  margin-left: 12px;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: 400;
  color: #777777;
}

.tipBtn2 {
  font-size: 16px;
  font-weight: 400;
  color: #333;
  width: 80px;
  height: 25px;
  border-radius: 4px;
  margin-left: 12px;
  box-sizing: border-box;
}
</style>
  