import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
Vue.prototype.$bus = new Vue()

import {
  Cell,
  CellGroup,
  Tabbar,
  TabbarItem,
  NavBar,
  Cascader, 
  Popup,
  ImagePreview,
  Overlay,
  Picker,
  Uploader,
  Sticky,
  Tab,
  Tabs,
  List,
  PullRefresh,
  Loading,
  Toast,
  Empty
} from 'vant'

Vue.config.productionTip = false
Vue.use(Cell)
Vue.use(CellGroup)
Vue.use(Tabbar)
Vue.use(TabbarItem)
Vue.use(NavBar)
Vue.use(Cascader)
Vue.use(Popup)
Vue.use(ImagePreview)
Vue.use(Overlay)
Vue.use(Picker)
Vue.use(Uploader)
Vue.use(Sticky)
Vue.use(Tab)
Vue.use(Tabs)
Vue.use(List)
Vue.use(PullRefresh)
Vue.use(Loading)
Vue.use(Toast)
Vue.use(Empty)
router.beforeEach(function (to, from, next) {
  if (to.meta.needLogin) {
    if (localStorage.getItem('wmdAllUser')) {
      // console.log('已登录')
      next();
    }else {
      // console.log('未登录')
      next({
        path:'/pwdLogin'
      });
    }
  }else{
    next();
  }
})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
