<template>
    <div id="pwdLogin">
      <van-sticky>
        <van-nav-bar title=""  @click-left="onClickLeft" />
      </van-sticky>
      <div class="kuang">
        <img :src="require('@/assets/logo.png')" class="avatar hidden">
        <div>HI<br>欢迎来到设备报修</div>
    </div>
      <div class="box p-lr16">
        <div class="one flex-wrap">
          <input type="tel" placeholder="请输入手机号" class="flex-con"  v-model="phone" maxlength="11">
        </div>
        <div class="one flex-wrap">
            <input type="password" placeholder="请输入密码" class="flex-con"  v-model="pwd">
        </div>
        <div class="txt flex-wrap">
            <div class="flex-con" @click="fnYzm">验证码登录</div>
            <div class="hei" @click="fnRegister">注册</div>
        </div>
        <div class="btn align-center" @click="fnLogin">
          登录
        </div>
        <div class="align-center xieyi">
        <input type="checkbox" v-model="isChecked" style="margin:0 6px 0 0;" />
        我已阅读并同意
        <span class="hei"  @click="fnSerive(2)">《用户协议》</span>
        及
        <span class="hei" @click="fnSerive(3)">《隐私协议》</span>
      </div>
      <div class="txt flex-wrap">
            <div class="flex-con"></div>
            <div class="hei" @click="fnforget" style="margin-top:20px;">忘记密码</div>
        </div>
      </div>
    </div>
  </template>
    <script>
  import { passwordLogin } from '../api'
  export default {
    data() {
      return {
          phone: '',
          pwd:'',
          isChecked:false
      }
    },
    mounted() {
      // this.initData()
    },
    methods: {
      onClickLeft() {
        this.$router.go(-1);
      },
      fnLogin(){
        if (this.phone == '') {
        this.$toast('请输入手机号')
        return;
      }
      if (!/^1[3456789]\d{9}$/.test(this.phone)) {
        this.$toast('请输入正确的手机号')
        return;
      }
      if (this.pwd == '') {
        this.$toast('请输入密码')
        return;
      }
      if(!this.isChecked){
        this.$toast('请勾选平台用户协议');
        return;
      }
      passwordLogin({
        phone: this.phone,
        pwd:this.pwd,
      }).then((data) => {
        if (data.status == 200) {
          localStorage.setItem('wmdAllUser', JSON.stringify(data.msg));  
          this.$router.push({ name: 'Main', params: {} })
        }
      })
      },
      fnRegister(){
        this.$router.push({ name: 'register', params: {} })
      },
      fnYzm(){
        this.$router.push({ name: 'phoneLogin', params: {} })
      },
      fnSerive(type){
        this.$router.push({ name: 'agreement',params:{type:type} })
      },
      fnforget(){
        this.$router.push({ name: 'forgetPwd',params:{} })
      }
    }
  }
    </script>
    
    <style scoped>
  ::v-deep .van-nav-bar__left {
    width: 24px;
    height: 44px;
    background: url("@/assets/left_arrow.png") no-repeat center center;
    background-size: 24px 24px;
  }
  #pwdLogin {
    height: 100%;
    background: url("@/assets/bg.png") repeat-y center top;
    background-size: 100% 100%;
  }
  
  .box{
      padding-top: 10px;
  }
  
  .one{
      height: 40px;
      border-radius: 4px;
      background: #ffffff;
      align-items: center;
      margin-bottom: 16px;
  }
  
  input[type="text"],input[type="tel"],input[type="password"] {
    border: none;
    border-radius: 4px;
    padding: 0 16px;
    color: 333;
    height: 40px;
  }
  
  input::-webkit-input-placeholder {
    font-size: 14px;
    font-weight: 400;
    color: #aaaaaa;
  }
  .btn_out {
    height: 40px;
    padding: 18px 16px;
    box-sizing: border-box;
  }
  
  .btn {
    height: 40px;
    background: #333333;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 500;
    color: #ffffff;
    letter-spacing: 2px;
  }
  .getCode{
      width: 86px;
      height: 30px;
      background: #333333;
      border-radius: 15px;
      margin-right: 12px;
      font-size: 12px;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 30px;
  }
  .kuang{
    padding:50px 16px;
    font-size: 20px;
    font-weight: 500;
    color: #333333;
    line-height: 34px;
  }
  .avatar{
    width: 90px;
    height: 90px;
    margin-bottom: 10px;
}
.txt{
    font-size: 14px;
    font-weight: 400;
    color: #777777;
    margin-bottom: 30px;
}
.xieyi{
    font-size: 14px;
    font-weight: 400;
    color: #777777;
    margin-top: 24px;
}
input[type='checkbox'] {
  -webkit-appearance: none;
  width: 14px;
  height: 14px;
  border: none;
  background: url('@/assets/check.png') no-repeat left center;
  background-size: 14px 14px;
}

input[type='checkbox']:checked {
  -webkit-appearance: none;
  width: 14px;
  height: 14px;
  border: none;
  background: url('@/assets/checked.png') no-repeat left center;
  background-size: 14px 14px;
}
.hei{
    color: #333;
}
  </style>
    