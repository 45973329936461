<template>
  <div id="mark">
    <div>
      <div class="top flex-wrap">
        <img :src="require('@/assets/md/side.png')" class="side" @click="show=true">
        <div class="flex-con"></div>
        <div @click="show2=true" v-if="auth==1">导出</div>
      </div>
      <div v-if="auth==1">

        <div class="markdown-body" style="position:relative;width:100%;height:auto;overflow:hidden;overflow-x: scroll;padding:0 16px 16px;box-sizing: border-box;">
          <div v-html="cate_content_html"></div>
          <div class="make">
            <div class="list">
              <div class="item" v-for="i in 300">
                <span>{{ info }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="comment">
          <div class="title">全部评论</div>
          <div class="avatar flex-wrap" v-for="(item,index) in comment">
            <img :src="item.user_image || require('@/assets/tou.png')" class="tou">
            <div class="flex-con">
              <div class="name" v-if="item.other_name">{{item.user_username}}回复{{item.other_name}}</div>
              <div class="name" v-else>{{item.user_username}}</div>
              <div class="pl">{{item.cm_content}}</div>
              <div class="time flex-wrap">
                <div>{{item.cm_time}}</div>
                <div class="huiAll" @click="fnComment(item.user_id,item.user_username)"><img :src="require('@/assets/news1.png')" class="hui">回复</div>
                <div class="flex-con"></div>
                <div v-if="item.user_id==user_id" @click="fnDel(item.cm_id)">删除</div>
              </div>
            </div>
          </div>
          <div class="bottom">
            <div class="kuang" @click="fnComment()">发布评论</div>
          </div>
        </div>

      </div>
      <div style="padding:56px 16px 16px;" v-else>
        <div class="title1 p-tb16">申请权限</div>
        <div class="flex-wrap m-b10  align-center">
          <div class="title2" style="letter-spacing: 3px;">电厂名称：</div>
          <input type="text" placeholder="请输入" class="flex-con" v-model="authMsg" style="height:40px;font-size:16px;">
        </div>
        <div class="btn align-center" style="margin-top:32px;"  @click="submit">
          提交审核
        </div>
      </div>

    </div>

    <van-popup v-model="show" position="left" :style="{ width: '80%', height: '100%' }">
      <div class="searchAll">
        <div class="searchBox flex-wrap">
          <input type="text" class="flex-con" placeholder="请输入关键词" v-model="keyword">
          <img :src="require('@/assets/md/search.png')" class="search" @click="fnSearch">
        </div>
      </div>
      <div v-for="(item,index) in list" :key="index">
        <div class="one flex-wrap" @click="fnMenu(item,index)">
          <div v-if="item.sons.length>0">
            <img :src="require('@/assets/md/open.png')" class="arrow" v-if="item.isOpen==false">
            <img :src="require('@/assets/md/close.png')" class="arrow1" v-else>
          </div>
          <div style="width:24px;" v-else></div>
          <div class="flex-con">{{item.cate_name_en}}</div>
        </div>
        <div class="child" v-for="item1 in item.sons" v-if="item.isOpen==true">
          <div class="label" :class="item.isOpen==true && item1.checked==true  ? 'labelActive' : ''" @click="fnSecond(item1)">{{item1.cate_name_en}}</div>
        </div>
      </div>
    </van-popup>
    <van-popup v-model="show1" position="bottom" :style="{ width: '100%', height: '30%' }">
      <div class="fb">
        <textarea :placeholder="place" v-model="content"></textarea>
        <div class="btn" @click="fnSend">发送</div>
      </div>
    </van-popup>
    <van-popup v-model="show2" round>
      <div class="tipBox">
        <div style="color:#333;font-size:14px;margin-bottom: 20px">
          是否确认导出？
        </div>
        <div class="align-between">
          <div class="tipBtn2 align-center" @click="fnPdf">确认</div>
          <div class="tipBtn1 align-center" @click="show2=false">取消</div>
        </div>
      </div>
    </van-popup>
    <NavBar></NavBar>
  </div>

</template>

<script>
import NavBar from '@/components/NavBar.vue'
import { dataList, dataInfo, userPublish, delCommReply, getPdf,userAsk } from '../api'
export default {
  components: { NavBar },
  data() {
    return {
      show: false,
      show1: false,
      show2: false,
      place: "请输入评论",
      info: JSON.parse(localStorage.getItem('wmdAllUser')).user_phone,
      user_id: JSON.parse(localStorage.getItem('wmdAllUser')).user_id,
      list: [],
      comment: [],
      cate_content_html: '',
      firstId: 0,
      keyword: '',
      cate_id: 0,
      uid: '',
      content: '',
      auth: 0,
      authMsg:''
    }
  },
  methods: {
    submit(){
      if (!this.authMsg) {
        this.$toast('请输入电厂名称')
        return
      }
      userAsk({
        content: this.authMsg,
      }).then((data) => {
        if (data.status == 200) {
          this.$toast('已提交成功')
        }
      })
    },
    fnPdf() {
      getPdf({
        cate_id: this.cate_id,
      }).then((data) => {
        if (data.status == 200) {
          this.show2 = false;
          let link = document.createElement('a');
          link.setAttribute('href', data.msg)
          link.click()
          return;
        }
      })
    },
    fnSend() {
      userPublish({
        content: this.content,
        cate_id: this.cate_id,
        uid: this.uid
      }).then((data) => {
        if (data.status == 200) {
          this.show1 = false;
          this.fnXq(this.cate_id);
        }
      })
    },
    fnComment(id, name) {
      this.content = ''
      if (id) {
        this.uid = id;
        this.place = "回复" + name;
      } else {
        this.place = "请输入评论";
      }
      this.show1 = true;
    },
    fnDel(id) {
      delCommReply({
        cm_id: id
      }).then((data) => {
        if (data.status == 200) {
          this.initData();
        }
      })
    },
    fnSearch() {
      this.initData(1);
    },
    initData(type) {
      dataList({
        keyword: this.keyword
      }).then((data) => {
        if (data.status == 200) {
          this.list = data.msg
          var that = this;
          this.list.forEach(function (item, index) {
            if (index == 0) {
              if (item.sons.length > 0) {
                that.list[index].isOpen = true;
              } else {
                that.list[index].isOpen = false;
              }
            } else {
              that.list[index].isOpen = false;
            }
            if (that.list[index].sons.length > 0) {
              that.list[index].sons.forEach(function (item1, index1) {
                that.list[index].sons[index1].checked = false;
              });
            }
          });
          this.auth = data.canShow.auth;  
          if (this.auth == 1) {
            if (this.list.length > 0) {
              this.firstId = this.list[0].cate_id;
              this.fnXq(this.firstId, type);
            }
          }else{
            this.authMsg = data.canShow.authMsg;
          }

        }
      })
    },
    fnMenu(menu, index) {
      var _this = this;
      if (menu.sons.length == 0) {
        _this.list.forEach(function (item, index) {
          _this.list[index].sons.forEach(function (item1, index1) {
            _this.list[index].sons[index1].checked = false;
          });
        });
        _this.fnXq(menu.cate_id);
        return;
      }
      if (menu.isOpen) {
        _this.$set(menu, 'isOpen', false);
      } else {
        _this.list.forEach(function (item, index) {
          if (item != menu) {
            _this.$set(item, 'isOpen', false);
          }
          _this.list[index].sons.forEach(function (item1, index1) {
            _this.list[index].sons[index1].checked = false;
          });
        });
        _this.$set(menu, 'isOpen', true);
      }
      if (menu.sons.length > 0) {
        _this.fnXq(menu.cate_id, 1);
      } else {
        _this.fnXq(menu.cate_id);
      }

      _this.$forceUpdate();
    },
    fnSecond(menu, index) {
      var _this = this;
      if (!menu.checked) {
        _this.list.forEach(function (item, index) {
          if (_this.list[index].sons.length > 0) {
            _this.list[index].sons.forEach(function (item1, index1) {
              _this.list[index].sons[index1].checked = false;
            });
          }
        });
        _this.$set(menu, 'checked', true);
      }
      _this.fnXq(menu.cate_id);
      _this.$forceUpdate();
    },
    fnXq(id, type) {
      this.cate_id = id;
      dataInfo({
        cate_id: this.cate_id
      }).then((data) => {
        if (data.status == 200) {
          this.cate_content_html = data.msg.cate_content_html;
          this.comment = data.comment;
        }
      })
      if (!type) {
        this.show = false;
      }

    },
    onClickLeft() {
      this.$router.go(-1)
    },
  },
  mounted() {
    this.initData();
  },
}
</script>
<style>
#mark {
  padding-bottom: 100px;
}

#mark .searchAll {
  padding: 12px 16px;
  box-sizing: border-box;
}

#mark .searchBox {
  height: 34px;
  background: #f8f8f8;
  border-radius: 4px;
  border: 1px solid #979797;
  align-items: center;
  padding-right: 12px;
  box-sizing: border-box;
}

#mark input[type="text"] {
  background: #f8f8f8;
  border: none;
  outline: none;
  padding: 0 12px;
  color: 333;
  font-size: 14px;
}

#mark input[type="text"]::-webkit-input-placeholder {
  font-size: 12px;
  font-weight: 400;
  color: #999;
}

#mark .search {
  width: 17px;
  height: 17px;
}
#mark .one {
  height: 30px;
  font-weight: 400;
  font-size: 16px;
  color: #333333;
  line-height: 30px;
  align-items: center;
  padding: 0 12px;
  box-sizing: border-box;
}
#mark .arrow {
  width: 12px;
  height: 16px;
  margin-right: 12px;
}
#mark .arrow1 {
  width: 15px;
  height: 13px;
  margin-right: 12px;
}
#mark .label {
  height: 30px;
  font-weight: 400;
  font-size: 14px;
  color: #777777;
  line-height: 30px;
  padding: 0 12px 0 40px;
  box-sizing: border-box;
}
#mark .labelActive {
  background: #f8f8f8;
}
#mark .side {
  width: 20px;
  height: 16px;
}
#mark .bottom {
  position: fixed;
  bottom: 50px;
  left: 0;
  width: 100%;
  padding: 12px 16px;
  box-sizing: border-box;
  box-sizing: border-box;
  background-color: #fff;
}
#mark .kuang {
  background: #f8f8f8;
  border-radius: 4px;
  border: 1px solid #979797;
  height: 30px;
  line-height: 30px;
  font-weight: 400;
  font-size: 12px;
  color: #333333;
  padding: 0 12px;
  box-sizing: border-box;
}
#mark .comment {
  padding: 0 16px;
  box-sizing: border-box;
}
#mark .title {
  font-size: 18px;
  font-weight: 500;
  color: #333333;
  margin-bottom: 15px;
}
#mark .avatar {
  margin-bottom: 15px;
}
#mark .tou {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
}
#mark .name {
  color: #777;
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 12px;
}
#mark .pl {
  color: #333;
  font-size: 16px;
  margin-bottom: 12px;
}
#mark .time {
  color: #777;
  font-size: 14px;
  align-items: center;
}
#mark .huiAll {
  margin-left: 15px;
}
#mark .hui {
  width: 14px;
  height: 13px;
  margin-right: 6px;
}
#mark .fb {
  padding: 16px;
  box-sizing: border-box;
}
#mark textarea {
  width: 100%;
  border-radius: 2px;
  background: #f8f8f8;
  border: none;
  color: 333;
  box-sizing: border-box;
  padding: 16px;
  box-sizing: border-box;
  font-size: 14px;
  height: 120px;
  outline: none;
}
#mark .btn {
  height: 40px;
  line-height: 40px;
  background: #333333;
  border-radius: 4px;
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
  letter-spacing: 2px;
  text-align: center;
  margin-top: 10px;
}
#mark .make {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 99;
  background: #00000000;
  pointer-events: none;
  overflow: hidden;
}
#mark .list {
  width: 100%;
  height: 100%;
  position: absolute;
  top: -50%;
  left: -30%;
  transform: rotate(-15deg);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  pointer-events: none;
}
#mark .item {
  font-size: 20px;
  color: rgba(220, 220, 220, 0.5);
  font-weight: bold;
  padding: 20px;
  box-sizing: border-box;
  pointer-events: none;
}
#mark .top {
  align-items: center;
  padding: 16px 16px 0;
  box-sizing: border-box;
}
#mark .tipBox {
  width: 275px;
  height: 105px;
  background: #ffffff;
  border-radius: 4px;
  padding: 20px;
  background: #fff;
  text-align: center;
  box-sizing: border-box;
}

#mark .tipBtn1 {
  width: 110px;
  height: 25px;
  border-radius: 4px;
  border: 1px solid #333333;
  color: #333333;
}

#mark .tipBtn2 {
  width: 110px;
  height: 25px;
  background: #333333;
  color: #fff;
  border-radius: 4px;
}
#mark .title1 {
  font-size: 18px;
  font-weight: 500;
  color: #333333;
  line-height: 22px;
}
#mark .title2 {
  font-size: 16px;
  color: #333333;
  line-height: 32px;
}
/* #mark textarea {
  width: 100%;
  height: 200px;
  margin-bottom: 20px;
  font-family: monospace;
} */

table {
  border-spacing: 0;
  border-collapse: collapse;
  display: block;
  width: 100%;
  overflow: auto;
  word-break: normal;
  word-break: keep-all;
}

table th {
  font-weight: bold;
}

table th,
table td {
  padding: 6px 13px;
  border: 1px solid #ddd;
}

table tr {
  background-color: #fff;
  border-top: 1px solid #ccc;
}

table tr:nth-child(2n) {
  background-color: #f8f8f8;
}
</style>
