import http from '../utils/request'

export const limit = 20
export const userAsk = (data) => { 
  //申请查看文档
  return http.post('api/Handbook/userAsk', data)
}
export const getPdf = (data) => { 
  //获取目录
  return http.post('api/Handbook/getPdf', data)
}
export const dataList = (data) => { 
  //获取目录
  return http.post('api/Handbook/dataList', data)
}
export const dataInfo = (data) => { 
  //获取某条详情
  return http.post('api/Handbook/dataInfo', data)
}
export const userPublish = (data) => { 
  //发布评论
  return http.post('api/Handbook/userPublish', data)
}
export const delCommReply = (data) => { 
  //删除评论
  return http.post('api/Handbook/delCommReply', data)
}

export const other = (data) => { 
  //其它项 入参：id  1 保修政策  2 用户协议  3 隐私协议
  return http.post('api/Other/other', data)
}

export const orderDetail = (data) => { 
  //订单详情
  return http.post('api/Order/orderDetail', data)
}

export const xlList = (data) => { 
  //系列列表
  return http.post('api/Xl/xlList', data)
}
export const sendCode = (data) => { 
  //发送验证码
  return http.post('api/Login/sendCode', data)
}
export const register = (data) => { 
  //注册
  return http.post('api/Login/register', data)
}
export const codeLogin = (data) => { 
  //验证码登录
  return http.post('api/Login/codeLogin', data)
}
export const passwordLogin = (data) => { 
  //账号密码登录
  return http.post('api/Login/passwordLogin', data)
}
export const forgetPassword = (data) => { 
  //忘记密码
  return http.post('api/Login/forgetPassword', data)
}
export const changePassword = (data) => { 
  //修改密码
  return http.post('api/Login/changePassword', data)
}

export const xhList = (data) => { 
  //型号列表
  return http.post('api/Xl/xhList', data)
}

export const gzList = (data) => { 
  //故障列表
  return http.post('api/GZ/gzList', data)
}

export const getArea = (data) => { 
  //获取地区列表
  return http.post('api/Area/getArea', data)
}

export const createOrder = (data) => { 
  //提交报修申请
  return http.post('api/Order/createOrder', data)
}
export const userInfo = (data) => { 
  //用户信息
  return http.post('api/My/userInfo', data)
}
export const updateUser = (data) => { 
  //修改用户信息
  return http.post('api/My/updateUser', data)
}
export const insertAddress = (data) => { 
  //新增收货地址
  return http.post('api/Address/insertAddress', data)
}
export const addressList = (data) => { 
  //用户收货地址列表
  return http.post('api/Address/addressList', data)
}
export const addressDetail = (data) => { 
  //收货地址详情
  return http.post('api/Address/addressDetail', data)
}
export const updateAddress = (data) => { 
  //编辑收货地址
  return http.post('api/Address/updateAddress', data)
}
export const orderList = (data) => { 
  //用户订单列表
  return http.post('api/Order/orderList', data)
}
export const cancelOrder = (data) => { 
  //取消订单
  return http.post('api/Order/cancelOrder', data)
}
export const sendOrder = (data) => { 
  //用户订单发货
  return http.post('api/Order/sendOrder', data)
}
export const overOrder = (data) => { 
  //确认收货
  return http.post('api/Order/overOrder', data)
}
export const orderWl = (data) => { 
  //物流信息
  return http.post('api/Order/orderWl', data)
}
export const createOrderCg = (data) => { 
  //存草稿
  return http.post('api/Order/createOrderCg', data)
}

export const orderDetailCg = (data) => { 
  //草稿详情
  return http.post('api/Order/orderDetailCg', data)
}



