<template>
  <div id="main" class="flex-vertical flex-wrap">

    <div class="top flex-wrap">
      <div class="flex-con"></div>
      <!-- <img :src="require('@/assets/logo.png')" class="logo"> -->
      <div>设备报修</div>
      <div class="flex-con"></div>
    </div>
    <div class="flex-con box">
      <div v-html="msg"></div>
    </div>
    <div class="btn_out">
      <div class="btn align-center" @click="sure">
        确认须知
      </div>
    </div>

    <NavBar></NavBar>
  </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import { other } from '../api'
export default {
  components: { NavBar },
  data() {
    return {
      msg: ''
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    initData() {
      other({
        id: 1
      }).then((data) => {
        // console.log(JSON.stringify( data));
        if (data.status == 200) {
          this.msg = data.data.content
          // console.log(JSON.stringify( data.data));
        }
      })
    },
    sure() {
      this.$router.push({
        name: 'AfterSales'
      })
    },
  },
}
</script>

<style scoped>
.group_2 {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 4px;
  align-self: center;
  margin-top: -1px;
}

.text_3 {
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 16px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 22px;
  align-self: center;
}

.text-wrapper_1 {
  width: 311px;
  height: 454px;
  overflow-wrap: break-word;
  font-size: 0;
  font-family: PingFangSC-Regular;
  font-weight: normal;
  text-align: left;
  line-height: 24px;
  margin-top: 12px;
}

.paragraph_1 {
  width: 311px;
  height: 454px;
  overflow-wrap: break-word;
  color: rgba(119, 119, 119, 1);
  font-size: 14px;
  font-family: PingFangSC-Regular;
  font-weight: normal;
  text-align: left;
  line-height: 24px;
}

.paragraph_2 {
  width: 311px;
  height: 454px;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 14px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  line-height: 24px;
}

.paragraph_3 {
  width: 311px;
  height: 454px;
  overflow-wrap: break-word;
  color: rgba(119, 119, 119, 1);
  font-size: 14px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  line-height: 24px;
}

.paragraph_4 {
  width: 311px;
  height: 454px;
  overflow-wrap: break-word;
  color: rgba(119, 119, 119, 1);
  font-size: 14px;
  font-family: PingFangSC-Regular;
  font-weight: normal;
  text-align: left;
  line-height: 24px;
}

.text_4 {
  width: 311px;
  height: 454px;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 14px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  line-height: 24px;
}

.paragraph_5 {
  width: 311px;
  height: 454px;
  overflow-wrap: break-word;
  color: rgba(119, 119, 119, 1);
  font-size: 14px;
  font-family: PingFangSC-Regular;
  font-weight: normal;
  text-align: left;
  line-height: 24px;
}

/* ///////////////////// */
.box {
  margin-bottom: 20px;
  padding: 16px;
  overflow-y: scroll;
}

.btn_out {
  height: 110px;
}

.btn {
  height: 40px;
  background: #333333;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 500;
  color: #FFFFFF;
  letter-spacing: 2px;
}

.box {
  background: #FFFFFF;
  border-radius: 4px;
}

#main {
  height: 100%;
  background: url('@/assets/bg.png') repeat-y center top;
  background-size: 100% 100%;
  padding: 0 16px;
}

.top {
  height: 44px;
  align-items: center;
  /* margin-bottom: 32px; */
}

.logo {
  width: 40px;
  height: 40px;
  margin-right: 8px;
}
</style>
