<template>
    <div  id="addressList">
      <!-- <van-sticky> -->
        <van-nav-bar title="收货地址" left-arrow @click-left="onClickLeft" :border="false" :safe-area-inset-top="true" />
      <!-- </van-sticky> -->
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
          <div class="box" style="padding:0 16px 80px;box-sizing:border-box;">

            <div class="card flex-wrap"  v-for="item in list"   @click="fnSelect(item)">
                <div class="flex-con">
                    <div>{{item.addr_username}} {{item.addr_phone}}</div>
                    <div>
                      {{item.sheng}}{{item.shi}}{{item.qu}}{{item.addr_address}}
                    </div>
                </div>
                <div class="xian"></div>
                <img :src="require('@/assets/edit.png')" class="edit"  @click.stop="fnXq(item.addr_id)">
            </div>
    
          </div>
        </van-list>
      </van-pull-refresh>
      <div class="bottom">
        <div class="btn" @click="fnXq(0)">添加收货地址</div>
      </div>
    </div>
  </template>
    
    <script>
  import { addressList } from '../api'
  export default {
    data() {
      return {
        page: 1,
        count: 0,
        list: [],
        loading: true,
        finished: false,
        refreshing: false,
      }
    },
    methods: {
      initData() {
        addressList({
          page:this.page
        }).then((data) => {
          if (data.status == 200) {
            this.count = data.count;
            if (this.refreshing) {
              this.list = data.msg
              this.refreshing = false
              if (this.list.length >= this.count) {
                this.finished = true
              }
              return
            }
            this.list = this.list.concat(data.msg)
            this.loading = false
            if (this.list.length >= this.count) {
              this.finished = true
            }
          }
        })
      },
      fnXq(id){
        this.$router.push({name:"addAddress",params:{addr_id:id}})
      },
      fnSelect(item){
        if(this.$route.params.isFrom){
          var wl=JSON.parse(localStorage.getItem('wlMsg'));
          wl.addr=item;
          localStorage.setItem('wlMsg', JSON.stringify(wl));
          this.$router.go(-1);  
        }
      },
      onLoad() {
        this.page++
        this.initData()
      },
      onRefresh() {
        this.finished = false
        this.loading = true
        this.page = 1
        this.initData()
      },
      onClickLeft() {
        this.$router.go(-1)
      },
    },
    mounted() {
    //   this.active = this.$route.params.type;
      this.initData();
    },
  }
    </script>
    
    <style scoped>
      ::v-deep .van-nav-bar__left {
      width: 24px;
      height: 44px;
      background: url("@/assets/left_arrow.png") no-repeat center center;
      background-size: 24px 24px;
      }
      #addressList {
      height: 100%;
      background: url('@/assets/bg.png') repeat-y center top;
      background-size: 100% 100%;
      }
      .van-list {
      min-height: calc(100vh - 88px);
      }
      .card{
        background: #FFFFFF;
        border-radius: 4px;
        padding: 9px 16px;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        line-height: 20px;
        align-items: center;
        margin-bottom: 10px;
      }
      .xian{
        width: 1px;
        height: 40px;
        border-right: 1px solid #C0C3C4;
        margin-left:42px;
      }
      .edit{
        width: 18px;
        height: 18px;
        margin-left: 11px;
      }
      .bottom{
        position: fixed;
        bottom:0;
        left:0;
        width: 100%;;
        padding: 20px 16px;
        box-sizing: border-box;
      }
      .btn{
        height: 40px;
        line-height: 40px;
        background: #333333;
        border-radius: 4px;
        font-size: 16px;
        font-weight: 500;
        color: #FFFFFF;
        letter-spacing: 2px;
        text-align: center;
      }
  </style>
    