<template>
  <div class="page-scan">
    <!--返回-->
    <van-nav-bar title="扫描二维码" :fixed="true" :placeholder="true" left-arrow @click-left="onClickLeft" />
    <!-- 扫码区域 -->
    <video ref="video" id="video" class="scan-video" autoplay></video>
    <!-- 提示语 -->
    <div v-show="tipShow" class="scan-tip">{{ tipMsg }}</div>
  </div>
</template>
   
  <script>
import { BrowserMultiFormatReader } from "@zxing/library";
export default {
  data() {
    return {
      loadingShow: false,
      codeReader: new BrowserMultiFormatReader(),
      scanText: "",
      vin: null,
      tipMsg: "正在尝试识别....",
      tipShow: false,
    };
  },
  mounted() {
    this.openScan();
  },
  unmounted: function () {
    // this.codeReader.reset(); //关闭摄像头
  },
  methods: {
    async openScan() {
      this.codeReader
        .getVideoInputDevices()
        .then((videoInputDevices) => {
          this.tipShow = true;
          this.tipMsg = "正在调用摄像头...";
          // 默认获取第一个摄像头设备id
          let firstDeviceId = videoInputDevices[0].deviceId;
          // 获取第一个摄像头设备的名称
          const videoInputDeviceslablestr = JSON.stringify(
            videoInputDevices[0].label
          );
           // 华为手机有6个摄像头，前三个是前置，后三个是后置，第6个摄像头最清晰
          if (videoInputDevices.length > 1) {
            if (videoInputDevices.length > 5) {
              firstDeviceId = videoInputDevices[5].deviceId;
            } else {
              // 判断是否后置摄像头
              if (videoInputDeviceslablestr.indexOf("back") > -1) {
                firstDeviceId = videoInputDevices[0].deviceId;
              } else {
                firstDeviceId = videoInputDevices[1].deviceId;
              }
            }
          }
          this.decodeFromInputVideoFunc(firstDeviceId);
        })
        .catch((err) => {
          this.tipShow = false;
          console.error(err);
        });
    },
    decodeFromInputVideoFunc(firstDeviceId) {
      this.codeReader.reset(); // 重置
      this.scanText = "";
      this.codeReader.decodeFromInputVideoDeviceContinuously(
        firstDeviceId,
        "video",
        (result, err) => {
          this.tipMsg = "正在尝试识别...";
          this.scanText = "";
          if (result) {
            //这里是扫码后的结果，具体怎么用要看项目需求
            // alert(result);
            this.tipShow = false;
            var wl=JSON.parse(localStorage.getItem('wlMsg'));
            wl.number=result.text;
            localStorage.setItem('wlMsg', JSON.stringify(wl));
            document.getElementById("video").srcObject.getTracks()[0].stop();
            this.$router.go(-1); 
          }
          if (err && !err) {
            this.tipMsg = "识别失败";
            setTimeout(() => {
              this.tipShow = false;
            }, 2000);
            alert(err);
          }
        }
      );
    },
    onClickLeft() {
      this.codeReader = null;
      this.$destroy();
      setTimeout(() => {
        this.$router.back();
      }, 200);
    },
  },
};
  </script>
   
  <style scoped>
::v-deep.van-nav-bar__title {
  font-size: 18px;
  font-weight: 800;
}
::v-deep .van-nav-bar .van-icon {
  color: #333;
  font-size: 24px;
}
.scan-video {
  height: 80vh;
  width: 100%;
}
.scan-tip {
  text-align: center;
  margin-bottom: 10vh;
  color: white;
  font-size: 5vw;
}
.page-scan {
  overflow-y: hidden;
  background-color: #363636;
}
</style>