<template>
  <div class="contain">
    <div class="page-scan flex-wrap flex-vertical" v-show="show111">
      <!--返回-->
      <van-nav-bar title="扫描条形码" :fixed="true" :placeholder="true" class="sao_title" right-text="取消"
        @click-right="onClickRight" />
      <div class="align-center flex-con">
        <video ref="video" id="video" class="scan-video" autoplay></video>
      </div>
      <!-- 提示语 -->
      <div v-show="tipShow" class="scan-tip">{{ tipMsg }}</div>
    </div>
    <div id="afterSales" v-show="!show111">
      <!-- <van-sticky> -->
      <van-nav-bar :title="title" left-arrow @click-left="onClickLeft" />
      <!-- </van-sticky> -->
      <div class="p-lr16">
        <div class="title1 p-tb16">个人信息</div>
        <div class="flex-wrap m-b10">
          <div class="title2" style="letter-spacing: 3px;">报修人：</div>
          <input type="text" placeholder="请输入您的姓名" class="flex-con" v-model="username" :readonly="!showClose">
        </div>
        <div class="flex-wrap m-b10">
          <div class="title2">联系电话：</div>
          <input type="tel" placeholder="请输入您的电话" class="flex-con" v-model="phone" :readonly="!showClose" maxlength="11">
        </div>
        <div class="flex-wrap m-b10">
          <div class="title2">单位名称：</div>
          <input type="text" placeholder="请输入您的单位名称" class="flex-con" v-model="company" :readonly="!showClose">
        </div>
        <div class="flex-wrap m-b10 relative" @click="getAreaData">
          <div class="title2">所在地区：</div>
          <input type="text" v-model="cascaderValue" placeholder="请选择您的所在地区" class="flex-con" readonly>
          <img :src="require('@/assets/bottom_arrow.png')" alt="" class="bottom_arrow">
        </div>
        <div class="flex-wrap">
          <div class="title2">详细地址：</div>
          <input type="text" placeholder="请输入您的详细地址" class="flex-con" v-model="address" :readonly="!showClose">
        </div>
        <!-- 调试所在地区时解开 -->
        <van-popup v-model="showArea" round position="bottom">
          <van-cascader title="请选择所在地区" :options="options" :field-names="fieldNames" @close="hideAreaPop"
            @finish="onFinishArea" active-color="#0089CE" />
        </van-popup>
        <div class="title1 p-tb16">添加产品</div>
        <div class="circle align-center" @click="addProduct" v-show="showClose">
          添加 <br>
          产品
        </div>
        <div v-show="orderInfo.length == 0">
          <div class="xian1"></div>
          <div class="wu">暂无产品</div>
        </div>
        <div class="product" v-show="orderInfo.length > 0" v-for="(item, index) in orderInfo" :key="'orderInfo' + index">
          <div class="title3 align-between">
            <div>
              产品系列
            </div>
            <img :src="require('@/assets/close.png')" alt="" class="close" @click="fnDeletePro(index)" v-show="showClose">
          </div>
          <div class="title4">
            {{ item.xl_name || '' }}
          </div>
          <div class="title3">
            详细型号
          </div>
          <div class="title4">
            {{ item.xh_name || '' }}
          </div>
          <div class="flex-wrap">
            <div class="flex-con">
              <div class="title3">
                序列号
              </div>
              <div class="title4">
                {{ item.oi_xulie || '' }}
              </div>
            </div>
            <div class="flex-con">
              <div class="title3">
                故障类型
              </div>
              <div class="title4">
                {{ item.gz_name || '' }}
              </div>
            </div>
          </div>
          <div class="title3">
            故障描述
          </div>
          <div class="title4">
            {{ item.oi_desc || '' }}
          </div>
          <div class="title3">
            问题照片
          </div>
          <div class="align-start problem_img" style="flex-wrap: wrap;">
            <img :src="item.oi_front_image" alt="" class="img1" @click="fnBig1(index, 0)">
            <img :src="item.oi_side_image" alt="" class="img1" @click="fnBig1(index, 1)">
            <img :src="item.oi_mp_image" alt="" class="img1" @click="fnBig1(index, 2)">
          </div>
          <div class="title3">
            其他
          </div>
          <div class="align-start problem_img" style="flex-wrap: wrap;">
            <div class="img1" style="position: relative;" @click="fnVideo(item.oi_other_video)"
              v-if="item.oi_other_video">
              <img :src="item.oi_other_video + '?x-oss-process=video/snapshot,t_1000,f_jpg,w_0,h_0,m_fast,ar_auto'" alt=""
                style="object-fit: cover;width:100%;height:100%;">
              <img :src="require('@/assets/play.png')" alt="" class="play">
            </div>
            <img v-for="(item1, index) in item.oi_other_image" :key="'img2_' + index" :src="item1" alt="" class="img1"
              @click="fnBig2(index, item.oi_other_image)">
          </div>
        </div>
      </div>

      <van-overlay :show="showVideo" @click="fnVideoClose">
        <video style="width:100%;height:100%;" @click="fnVideoClose" :src="bigVideo" autoplay="true" id="video111"
          preload>
        </video>
      </van-overlay>

      <div class="btn_out" @click="submit">
        <div class="btn align-center">
          提交审核
        </div>
      </div>

      <van-overlay :show="overlayShow" :lock-scroll='false'>
        <div class="wrapper" @click.stop>
          <div style="width:calc(100% - 32px);">
            <div class="block">
              <div class="tan_title1">选择产品</div>
              <img :src="require('@/assets/close.png')" alt="" class="close_tan" @click="fnCloseOverlay" />
              <div class="scroll1">
                <div class="flex-wrap m-b10 relative" @click="fnPicker1(1)">
                  <div class="title2">产品系列：</div>
                  <input type="text" v-model="name1" placeholder="请选择产品系列" class="flex-con" readonly>
                  <img :src="require('@/assets/bottom_arrow.png')" alt="" class="bottom_arrow">
                </div>
                <div class="flex-wrap m-b10 relative" @click="fnPicker1(2)">
                  <div class="title2">详细型号：</div>
                  <input type="text" v-model="name2" placeholder="请选择详细型号" class="flex-con" readonly>
                  <img :src="require('@/assets/bottom_arrow.png')" alt="" class="bottom_arrow">
                </div>
                <div class="flex-wrap m-b10 relative">
                  <div class="title2">序列号：</div>
                  <input type="text" placeholder="请扫描序列号" class="flex-con" v-model="xulie">
                  <img :src="require('@/assets/sao.png')" alt="" class="bottom_arrow" @click="fnScan">
                </div>
                <div class="flex-wrap m-b10 relative" @click="fnPicker1(3)">
                  <div class="title2">故障类型：</div>
                  <input type="text" v-model="name3" placeholder="请选择故障类型" class="flex-con" readonly>
                  <img :src="require('@/assets/bottom_arrow.png')" alt="" class="bottom_arrow">
                </div>
                <div class="flex-wrap m-b10">
                  <div class="title2">详细描述：</div>
                  <textarea placeholder="请输入" class="flex-con" style="height:65px;" v-model="desc"></textarea>
                </div>
                <div class="m-b10">
                  <div class="title2" style="line-height: 20px;">上传照片：</div>
                </div>
                <div class="align-between m-b10 three">

                  <van-uploader v-model="imgZheng" :preview-full-image="false" :max-count="1" @delete="onBeforeDelete(1)"
                    :after-read="(file) => OnAfterRead(file, 1)" preview-size="90">
                    <div class="img_bg">
                      <div class="img_txt">正面</div>
                    </div>
                  </van-uploader>
                  <van-uploader v-model="imgCe" :preview-full-image="false" :max-count="1" @delete="onBeforeDelete(2)"
                     :after-read="(file) => OnAfterRead(file, 2)" preview-size="90">
                    <div class="img_bg">
                      <div class="img_txt">侧面</div>
                    </div>
                  </van-uploader>
                  <van-uploader v-model="imgMing" :preview-full-image="false" :max-count="1" @delete="onBeforeDelete(3)"
                     :after-read="(file) => OnAfterRead(file, 3)" preview-size="90">
                    <div class="img_bg">
                      <div class="img_txt">铭牌面</div>
                    </div>
                  </van-uploader>

                </div>
                <div class="m-b10">
                  <div class="title2" style="line-height: 20px;">其他：</div>
                </div>
                <div class="m-b10">
                  <van-uploader v-model="other_img" :max-count="9" :preview-full-image="false" multiple class="otherImg"
                    @delete="onBeforeDelete5" 
                    :after-read="(file) => OnAfterReadmul(file, 5)" preview-size="90">
                    <!-- :before-read="onBeforeReadMul" -->
                    <div class="img_bg">
                      <div class="img_txt">照片</div>
                    </div>
                  </van-uploader>
                </div>
                <div class="m-b10">
                  <van-uploader v-model="other_video" :after-read="(file) => OnAfterRead(file, 4)" :max-count="1"
                    accept='video/*' :preview-full-image="false" preview-size="90" class="other_video1">
                    <div class="img_bg">
                      <div class="img_txt">视频</div>
                    </div>
                    <!-- :before-read="onBeforeRead" -->
                    <template #preview-cover="{ other_video }">
                      <img :src="other_video1 + '?x-oss-process=video/snapshot,t_1000,f_jpg,w_0,h_0,m_fast,ar_auto'"
                        alt="" class="other_video" style="object-fit: cover;">
                      <img :src="require('@/assets/play.png')" alt="" class="play">
                    </template>
                  </van-uploader>
                </div>
              </div>
              <div class="btn align-center" @click="sure">
                确认
              </div>
            </div>

          </div>
        </div>
      </van-overlay>
      <van-popup v-model="showPicker1" round position="bottom">
        <van-picker title="产品系列" show-toolbar :columns="columns1" @cancel="showPicker1 = false" @confirm="onConfirm1">
          <template #option="option">
            <div style="display: flex; flex-direction: column; align-items: center;">
              <div>{{ option.device_name }}</div>
            </div>
          </template>
        </van-picker>
      </van-popup>
      <van-popup v-model="showPicker2" round position="bottom">
        <van-picker title="详细型号" show-toolbar :columns="columns2" @cancel="showPicker2 = false" @confirm="onConfirm2">
          <template #option="option">
            <div style="display: flex; flex-direction: column; align-items: center;">
              <div>{{ option.device_name }}</div>
            </div>
          </template>
        </van-picker>
      </van-popup>
      <van-popup v-model="showPicker3" round position="bottom">
        <van-picker title="故障类型" show-toolbar :columns="columns3" @cancel="showPicker3 = false" @confirm="onConfirm3">
          <template #option="option">
            <div style="display: flex; flex-direction: column; align-items: center;">
              <div>{{ option.gz_name }}</div>
            </div>
          </template>
        </van-picker>
      </van-popup>
    </div>
  </div>
</template>
<script>
import { BrowserMultiFormatReader } from "@zxing/library";
import Compressor from 'compressorjs';
import { ImagePreview, Toast } from 'vant';
import { getArea, xlList, xhList, gzList, createOrder, orderDetailCg, createOrderCg } from '../api'
import { put } from '@/utils/ali-oss'

export default {
  data() {
    return {
      show111: false,
      imgZheng: [],
      imgCe: [],
      imgMing: [],
      imgZheng1: '',
      imgCe1: '',
      imgMing1: '',
      other_video: [],
      other_video1: '',
      other_img: [],
      other_img1: [],
      imgThree: '',
      username: '',
      phone: '',
      company: '',
      address: '',
      desc: '',
      xulie: '',
      orderInfo: [],
      title: '售后申请',
      showVideo: false,
      bigVideo: '',
      cascaderValue: '',
      pickerType: '',
      id1: '',
      id2: '',
      id3: '',
      name1: '',
      name2: '',
      name3: '',
      showClose: true,
      overlayShow: false,
      showPicker1: false,
      showPicker2: false,
      showPicker3: false,
      showArea: false,
      columns1: [],
      columns2: [],
      columns3: [],
      options: [],
      addr_province: '',
      addr_city: '',
      addr_district: '',
      fieldNames: {
        text: 'district',
        value: 'district_id',
        children: 'items',
      },
      loadingShow: false,
      codeReader: null,
      scanText: "",
      vin: null,
      tipMsg: "正在尝试识别....",
      tipShow: false,
    }
  },
  mounted() {
    this.initDataCg()
  },
  methods: {
    OnAfterReadmul(e, type) {
      e.forEach(async element => {
        await this.OnAfterRead(element, type)
      });
      return new Promise(resolve => resolve(true));
    },
    OnAfterRead(e, type) {
      let _self = this;
      Toast.loading({
        duration: 100000, // 持续展示 toast
        forbidClick: true,
        message: '上传中',
      });
      let files = e.file
      // alert(files.name + '||' + files.size + '||' + files.type);
      let objName = new Date().getTime()+files.name.split('.')[1]


      put(`${objName}`, e.file).then(res => {
        // alert('res||' + res.url);
        console.log(res)
        Toast.clear();
        if (res.res.statusCode === 200) {
          switch (type) {
            case 1:
              _self.imgZheng1 = res.url
              break
            case 2:
              _self.imgCe1 = res.url
              break
            case 3:
              _self.imgMing1 = res.url
              break
            case 4:
              _self.other_video1 = res.url
              break
            case 5:
              _self.other_img1.push(res.url)
              break
            default:
              break
          }
        } else {
          this.$toast('上传失败')
          // this.$message.error('上传失败')
        }
      }).catch((err) => {
        Toast.clear();
        this.$toast('上传失败')
        console.log(err)
        // alert('上传失败',JSON.stringify(err));
      })

    },
    onBeforeRead(e) {
      // alert(e.name+'|'+e.type+'|'+e.size)
      // return
      return new Promise((resolve, reject) => {
        new Compressor(e, {
          //压缩质量, 0-1
          quality: 0.6,
          //转换的类型，默认为 image/png
          convertTypes: ['image/jpeg', 'image/png'],
          //需要压缩的起始大小，默认2M, 2 * 1000 * 1000
          convertSize: 2000000,
          //压缩成功后处理
          success(result) {
            let file = new File([result], e.name, { type: e.type })
            // console.log('408 ' + file);
            resolve(file);
          },
          error(err) {
            // alert('682||' + JSON.stringify(err))
            // console.log(err.message);
            reject()
          },
        });
      });
    },
    async onBeforeReadMul(file1) {
      let file = file1
      if (!file.length) {
        file = [file]
      }
      let _this = this
      let imgs = []
      for (let i = 0; i < file.length; i++) {
        let img = file[i];
        //   const fileType = img.name.substring(img.name.indexOf('.') + 1);
        //  // 判断文件是不是jpeg 不是jpeg的都转成jpeg 
        //   if (!['jpeg', 'jpg'].includes(fileType)) {
        //     img = await ConvertImage(img);  //转成jpeg格式的file
        //   }
        let img1 = await _this.onBeforeRead(img); //图片压缩
        imgs.push(img1)
      }
      return new Promise(resolve => resolve(imgs));
    },
    sure() {
      if (!this.id1) {
        this.$toast('请选择产品系列')
        return
      }
      if (!this.id2) {
        this.$toast('请选择详细型号')
        return
      }
      if (!this.xulie) {
        this.$toast('请扫描序列号')
        return
      }
      if (!this.id3) {
        this.$toast('请选择故障类型')
        return
      }
      if (!this.desc) {
        this.$toast('请输入详细描述')
        return
      }
      if (!this.imgZheng1) {
        this.$toast('请上传产品正面照片')
        return
      }
      if (!this.imgCe1) {
        this.$toast('请上传产品侧面照片')
        return
      }
      if (!this.imgMing1) {
        this.$toast('请上传产品铭牌面照片')
        return
      }

      let obj = {
        oi_xl: this.id1,
        oi_xh: this.id2,
        oi_gz: this.id3,
        xl_name: this.name1,  // 系列
        xh_name: this.name2,  // 型号
        gz_name: this.name3,  // 故障类型
        oi_xulie: this.xulie,  // 序列号
        oi_desc: this.desc,  // 故障描述
        oi_front_image: this.imgZheng1,  // 前图片
        oi_side_image: this.imgCe1,  // 侧图片
        oi_mp_image: this.imgMing1,  // 名牌图片
        oi_other_image: this.other_img1,  // 其它图片
        oi_other_video: this.other_video1,  // 其它视频
      }
      this.orderInfo.push(obj);
      this.fnCreateCg()
      this.fnCloseOverlay()
    },


    initDataCg() {
      orderDetailCg({}).then((data) => {
        if (data.status == 200) {
          if (!data.order) {
            return
          }
          this.orderInfo = data.orderInfo
          this.username = data.order.order_username
          this.phone = data.order.order_phone
          this.company = data.order.order_company
          this.addr_province = data.order.order_sheng
          this.addr_city = data.order.order_shi
          this.addr_district = data.order.order_qu
          this.address = data.order.order_address
          this.cascaderValue = (data.order.sheng || '') + (data.order.shi || '') + (data.order.qu || '')
        }
      })
    },
    fnCreateCg() {
      let arr = JSON.parse(JSON.stringify(this.orderInfo));
      if (arr.length > 0) {
        arr.forEach(element => {
          element.oi_other_image = element.oi_other_image.join(',')
        });
      }

      createOrderCg({
        username: this.username,//     报修人
        phone: this.phone,//        报修人手机号
        company: this.company,//      公司
        sheng: this.addr_province,//        省ID
        shi: this.addr_city,//          市ID
        qu: this.addr_district,//           区ID
        address: this.address,//      详细地址
        device: JSON.stringify(arr)
      }).then((data) => {
        if (data.status == 200) {
        }
      })
    },


    async openScan() {
      this.codeReader
        .getVideoInputDevices()
        .then((videoInputDevices) => {
          this.tipShow = true;
          this.tipMsg = "正在调用摄像头...";
          // 默认获取第一个摄像头设备id
          let firstDeviceId = videoInputDevices[0].deviceId;
          // 获取第一个摄像头设备的名称
          const videoInputDeviceslablestr = JSON.stringify(
            videoInputDevices[0].label
          );
          // 华为手机有6个摄像头，前三个是前置，后三个是后置，第6个摄像头最清晰
          if (videoInputDevices.length > 1) {
            if (videoInputDevices.length > 5) {
              firstDeviceId = videoInputDevices[5].deviceId;
            } else {
              // 判断是否后置摄像头
              if (videoInputDeviceslablestr.indexOf("back") > -1) {
                firstDeviceId = videoInputDevices[0].deviceId;
              } else {
                firstDeviceId = videoInputDevices[1].deviceId;
              }
            }
          }
          this.decodeFromInputVideoFunc(firstDeviceId);
        })
        .catch((err) => {
          this.tipShow = false;
          console.error(err);
        });
    },
    decodeFromInputVideoFunc(firstDeviceId) {
      this.codeReader.reset(); // 重置
      this.scanText = "";
      this.codeReader.decodeFromInputVideoDeviceContinuously(
        firstDeviceId,
        "video",
        (result, err) => {
          this.tipMsg = "正在尝试识别...";
          this.scanText = "";
          if (result) {
            this.$toast('识别成功')
            this.xulie = result.text || result;
            setTimeout(() => {
              this.show111 = false
              document.getElementById("video").srcObject.getTracks()[0].stop();
            }, 500);
            //这里是扫码后的结果，具体怎么用要看项目需求
          }
          if (err && !err) {
            this.tipMsg = "识别失败";
            setTimeout(() => {
              this.tipShow = false;
            }, 2000);
            this.$toast(err)
          }
        }
      );
    },
    submit() {
      if (!this.showClose) {
        return
      }
      if (!this.username) {
        this.$toast('请输入您的姓名')
        return
      }

      if (!this.phone) {
        this.$toast('请输入您的电话')
        return
      }
      if (!/^1[3456789]\d{9}$/.test(this.phone)) {
        this.$toast('请输入正确的电话')
        return;
      }
      if (!this.company) {
        this.$toast('请输入您的单位名称')
        return
      }
      if (!this.addr_province) {
        this.$toast('请选择您的所在地区')
        return
      }
      if (!this.address) {
        this.$toast('请输入您的详细地址')
        return
      }
      if (this.orderInfo.length == 0) {
        this.$toast('请添加产品')
        return
      }

      // this.orderInfo = [
      //   {
      //     oi_xl: 5,
      //     oi_xh: 9,
      //     oi_gz: 1,
      //     xl_name: "系列1",
      //     xh_name: "型号444",
      //     gz_name: "开不了机",
      //     oi_xulie: "12345678",
      //     oi_desc: "我是详细描述",
      //     oi_front_image: "https://benbull.oss-cn-nanjing.aliyuncs.com/050a512c48ff55772143d70d2cdd2c28.jpg",
      //     oi_side_image: "https://benbull.oss-cn-nanjing.aliyuncs.com/b43cec2e922e741b53331e5437603b5e.jpg",
      //     oi_mp_image: "https://benbull.oss-cn-nanjing.aliyuncs.com/7d28a39792af35719f0a54f98da12754.jpg",
      //     oi_other_image: ['https://benbull.oss-cn-nanjing.aliyuncs.com/0276c06011993a16ec63705dc16e6d29.jpg'],
      //     oi_other_video: ""
      //   }
      // ]


      let arr = JSON.parse(JSON.stringify(this.orderInfo));
      arr.forEach(element => {
        element.oi_other_image = element.oi_other_image.join(',')
      });
      let _this = this

      createOrder({
        username: this.username,//     报修人
        phone: this.phone,//        报修人手机号
        company: this.company,//      公司
        sheng: this.addr_province,//        省ID
        shi: this.addr_city,//          市ID
        qu: this.addr_district,//           区ID
        address: this.address,//      详细地址
        device: JSON.stringify(arr)
      }).then((data) => {
        if (data.status == 200) {
          this.showClose = false
          this.$toast('订单已提交成功')
          this.onClickLeft()
        }
      })
    },


    fnBig1(index1, index2) {
      let arr = [];
      arr[0] = this.orderInfo[index1].oi_front_image;
      arr[1] = this.orderInfo[index1].oi_side_image;
      arr[2] = this.orderInfo[index1].oi_mp_image;
      ImagePreview({
        images: arr,
        startPosition: index2,
      });
    },
    fnBig2(index2, arr) {
      ImagePreview({
        images: arr,
        startPosition: index2,
      });
    },
    onBeforeDelete(type) {
      switch (type) {
        case 1:
          this.imgZheng1 = ''
          break
        case 2:
          this.imgCe1 = ''
          break
        case 3:
          this.imgMing1 = ''
          break
        case 4:
          this.other_video1 = ''
          break
        default:
          break
      }
    },
    fnVideo(video) {
      this.bigVideo = video
      this.showVideo = true
      let video1 = document.getElementById('video111')
      setTimeout(() => {
        video1.play()
      }, 500);
    },
    fnVideoClose() {
      let video1 = document.getElementById('video111')
      video1.pause()
      this.showVideo = false

    },
    fnDeletePro(index) {
      this.orderInfo.splice(index, 1)
      this.fnCreateCg()
    },
    onBeforeDelete5(file, detail) {
      this.other_img1.splice(detail.index, 1)
    },
    fnScan() {
      this.show111 = true
      this.codeReader = new BrowserMultiFormatReader();
      this.codeReader.reset();
      this.openScan();
    },
    onFinishArea({ selectedOptions, tabIndex }) {
      this.showArea = false;
      var areaName = selectedOptions.map((option) => option.district).join('');
      var areaNameId = selectedOptions.map((option) => option.district_id);
      this.cascaderValue = areaName;
      this.addr_province = areaNameId[0];
      this.addr_city = areaNameId[1];
      this.addr_district = areaNameId[2];
    },
    hideAreaPop() {
      this.showArea = false;
    },
    fnPicker1(type) {
      if (type == 2 && !this.id1) {
        this.$toast('请选择产品系列')
        return
      }
      if (type == 3 && !this.id2) {
        this.$toast('请选择详细型号')
        return
      }
      this['showPicker' + type] = true;
      switch (type) {
        case 1:
          this.initDataXl()
          break
        case 2:
          this.initDataXh()
          break
        case 3:
          this.initDataGz()
          break
        default:
          break
      }
    },
    getAreaData() {
      if (!this.showClose) {
        return
      }
      getArea({}).then((data) => {
        if (data.status == 200) {
          this.options = data.data;
          this.showArea = true;
        }
      })
    },
    initDataXl() {
      xlList({}).then((data) => {
        if (data.status == 200) {
          this.columns1 = data.msg
        }
      })
    },
    initDataXh() {
      xhList({
        id: this.id1
      }).then((data) => {
        if (data.status == 200) {
          this.columns2 = data.msg
        }
      })
    },
    initDataGz() {
      gzList({
        id: this.id2
      }).then((data) => {
        if (data.status == 200) {
          this.columns3 = data.msg
        }
      })
    },
    onConfirm1(value, index) {
      this.showPicker1 = false;
      this.id1 = value.device_id;
      this.name1 = value.device_name;
      this.id2 = '';
      this.id3 = '';
      this.name2 = '';
      this.name3 = '';
    },
    onConfirm2(value, index) {
      this.showPicker2 = false;
      this.id2 = value.device_id;
      this.name2 = value.device_name;
      this.id3 = '';
      this.name3 = '';
    },
    onConfirm3(value, index) {
      this.showPicker3 = false;
      this.id3 = value.gz_id;
      this.name3 = value.gz_name;
    },
    addProduct() {
      this.overlayShow = true;
      this.fnCreateCg()
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    fnCloseOverlay() {
      this.overlayShow = false
      this.id1 = '';
      this.id2 = '';
      this.id3 = '';
      this.name1 = '';
      this.name2 = '';
      this.name3 = '';
      this.xulie = '';
      this.desc = '';
      this.imgZheng1 = '';
      this.imgCe1 = '';
      this.imgMing1 = '';
      this.other_img = [];
      this.other_img1 = [];
      this.other_video = [];
      this.other_video1 = '';
      this.imgZheng = [];
      this.imgCe = [];
      this.imgMing = [];
      document.getElementById("video").srcObject.getTracks()[0].stop();
    },
    onClickRight() {
      this.show111 = false
      document.getElementById("video").srcObject.getTracks()[0].stop();
    },
  },
}
</script>

<style scoped>
::v-deep .van-nav-bar__text {
  color: #fff;
}

.otherImg,
.otherImg>.van-uploader__wrapper,
.otherImg>.van-uploader__wrapper>.van-uploader__preview {
  width: 100%;
}


::v-deep .otherImg .van-uploader__wrapper>.van-uploader__preview {
  margin-bottom: 10px;
  margin-right: calc((100% - 270px) / 2);
}

::v-deep .otherImg .van-uploader__wrapper>.van-uploader__preview:nth-child(3n) {
  margin-right: 0;
}

::v-deep .three .van-uploader__preview {
  margin: 0;
}

::v-deep .other_video1 .van-uploader__preview {
  margin-right: 0;
}

.preview-cover {
  position: absolute;
  bottom: 0;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  /* padding: 4px; */
  /* color: #fff; */
  /* font-size: 12px;
  text-align: center;
  background: rgba(0, 0, 0, 0.3); */
}

.van-uploader__preview-cover .other_video {
  width: 100%;
  height: 100%;
  position: relative;
}

.van-uploader__preview-cover .play {
  position: absolute;
  width: 20px;
  height: 20px;
  margin: auto;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}

.play {
  position: absolute;
  width: 20px;
  height: 20px;
  margin: auto;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}

.doc {
  border-radius: 4px;
  border: 1px solid #F1F1F1;
  padding: 10px 12px;
  margin-bottom: 16px;
}

.refuse {
  font-size: 14px;
  font-weight: 400;
  color: #AAAAAA;
  line-height: 20px;
  background: #FFFFFF;
  border-radius: 4px;
  padding: 6px 16px;
}

.img_bg {
  width: 90px;
  height: 90px;
  background: url('@/assets/img_bg.png') no-repeat center center;
  background-size: 100% 100%;
}

.img_bg .img_txt {
  position: absolute;
  bottom: 10px;
  width: 100%;
  text-align: center;
}

textarea {
  border-radius: 2px;
  border: 1px solid #F7F7F7;
  color: 333;
  box-sizing: border-box;
  padding: 6px 16px;
  font-size: 14px;
}

.block input[type="text"] {
  border: 1px solid #F7F7F7;
  border-radius: 2px;
  padding: 0 16px;
  color: 333;
  box-sizing: border-box;
}

.tan_title1 {
  font-weight: 500;
  margin-bottom: 10px;
  text-align: center;
}

.close_tan {
  width: 18px;
  height: 18px;
  position: absolute;
  right: 16px;
  top: 16px;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.block {
  background: #ffffff;
  border-radius: 4px;
  font-size: 14px;
  color: #333333;
  line-height: 20px;
  padding: 16px;
  position: relative;
  height: 80vh;
}

.img1 {
  width: 90px;
  height: 90px;
  object-fit: cover;
}

.problem_img {
  margin-bottom: 16px;
}

.problem_img>.img1:nth-child(3n+2) {
  display: block;
  margin: 0 calc((100% - 270px)/2);
}

.close {
  width: 18px;
  height: 18px;
}

.title4 {
  font-size: 14px;
  color: #333333;
  line-height: 20px;
  margin-bottom: 16px;
}

.title3 {
  font-size: 14px;
  font-weight: 500;
  color: #333333;
  line-height: 20px;
  margin-bottom: 6px;
}

.product {
  background: #FFFFFF;
  border-radius: 4px;
  padding: 12px 12px 2px;

}

.product:not(:last-child) {
  margin-bottom: 12px;
}

.btn_out {
  position: fixed;
  width: 100%;
  bottom: 20px;
  height: 40px;
  padding: 0 16px;
  box-sizing: border-box;
}

.btn {
  height: 40px;
  background: #333333;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 500;
  color: #FFFFFF;
  letter-spacing: 2px;
}

.circle {
  position: fixed;
  top: 54vh;
  right: 6px;
  width: 100px;
  height: 100px;
  background: url('@/assets/circle.png') no-repeat center center;
  background-size: 100% 100%;
  font-size: 18px;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 25px;
}

.xian1 {
  background-color: #fff;
  height: 1px;
}

.wu {
  font-size: 16px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 22px;
  letter-spacing: 2px;
  text-align: center;
  margin-top: 19px;
}

.bottom_arrow {
  position: absolute;
  width: 18px;
  height: 18px;
  right: 16px;
  top: 7px;
}

.right_arrow {
  width: 18px;
  height: 18px;
  margin-left: 8px;
}

input[type="text"],
input[type="tel"] {
  border: none;
  border-radius: 4px;
  padding: 0 16px;
  color: 333;
  font-size: 14px;
}


input[type="text"]::-webkit-input-placeholder,
input[type="tel"]::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  font-size: 14px;
  font-weight: 400;
  color: #AAAAAA;
}

.title2 {
  font-size: 14px;
  color: #333333;
  line-height: 32px;
  width: 72px;
}


#afterSales {
  height: calc(100% - 80px);
  background: url('@/assets/bg.png') repeat-y center top;
  background-size: 100% 100%;
  padding-bottom: 80px;
  overflow-y: scroll;
}

.contain {
  height: 100%;
}

::v-deep .van-nav-bar__left {
  width: 24px;
  height: 44px;
  background: url('@/assets/left_arrow.png') no-repeat center center;
  background-size: 24px 24px;
}

.title1 {
  font-size: 16px;
  font-weight: 500;
  color: #333333;
  line-height: 22px;
}


::v-deep .sao_title .van-nav-bar__title {
  color: #fff;
}

.scan-video {
  height: 80vh;
  width: 100%;
}

.scan-tip {
  height: 200px;
  text-align: center;
  /* margin-bottom: 10vh; */
  color: white;
  font-size: 5vw;
}

.page-scan {
  /* overflow-y: hidden; */
  background-color: #363636;
  height: 100vh;
}

.scroll1 {
  height: 70vh;
  overflow-y: scroll;
}

.scroll1::-webkit-scrollbar {
  display: none;
}
</style>
