<template>
  <div id="wuliu">
    <van-nav-bar title="填写物流信息" left-arrow @click-left="onClickLeft" />
    <div class="p-lr16">
      <div class="flex-wrap m-b10" style="margin-top:16px;">
        <div class="title2">邮寄地址：</div>
        <div class="address1 flex-con">
            {{order.order_company_name || '' }}收 &nbsp;  {{order.order_company_phone || '' }} <br>
            {{order.order_company_address || '' }}
        </div>
      </div>
      <div class="flex-wrap m-b10 relative">
        <div class="title2">快递单号：</div>
        <input type="text" placeholder="请输入快递单号" class="flex-con" v-model="express_code">
        <img :src="require('@/assets/sao.png')" @click="fnScan" class="bottom_arrow">
      </div>
      <div class="flex-wrap m-b10 relative">
        <div class="title2">快递公司：</div>
        <input type="text" placeholder="请输入快递公司" class="flex-con" v-model="express_company">
      </div>
      <div class="flex-wrap relative"  @click="fnAddr">
        <div class="title2">回邮地址：</div>
        <input type="text" placeholder="选择回邮地址 非必填" v-model="address" class="flex-con" readonly>
        <img :src="require('@/assets/right_arrow.png')" alt="" class="bottom_arrow">
      </div>
    </div>
    <div class="btn_out">
      <div class="btn align-center" @click="fnSure">
        确认
      </div>
    </div>

  </div>
</template>
  
<script>
import { orderDetail,sendOrder } from '../api'
export default {
  data() {
    return {
        id:'',
        order: {},
        express_company:'',
        express_code:'',
        addr_id:'',
        addr:{},
        address:''
    }
  },
  mounted() {
    this.id = this.$route.params.id;
    var wlData=JSON.parse(localStorage.getItem('wlMsg'));
    if(localStorage.getItem('wlMsg')){
        this.express_company=wlData.company;
        if(wlData.number){
            this.express_code=wlData.number;
        }
        if(wlData.addr.sheng){
            this.addr=wlData.addr;
            this.address=wlData.addr.sheng+wlData.addr.shi+wlData.addr.qu+wlData.addr.addr_address;
            this.addr_id=wlData.addr.addr_id;
        }
    }
    localStorage.removeItem("wlMsg");
    this.initData()
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    initData() {
      orderDetail({
        id: this.id
      }).then((data) => {
        if (data.status == 200) {
          this.order = data.order
        }
      })
    },
    fnScan(){
        var wl={};
        wl.number=this.express_code;
        wl.company=this.express_company;
        wl.addr=this.addr;
        localStorage.setItem('wlMsg', JSON.stringify(wl));
         this.$router.push({
            name: 'qrcode'
         });
    },
    fnAddr(){
        var wl={};
        wl.number=this.express_code;
        wl.company=this.express_company;
        wl.addr=this.addr;
        localStorage.setItem('wlMsg', JSON.stringify(wl));
        this.$router.push({ name: 'addressList', params: { isFrom:1 } })
    },
    fnSure() {
        if (this.express_company == '') {
            this.$toast('请输入快递单号')
            return;
        }
        if (this.express_code == '') {
            this.$toast('请输入快递公司')
            return;
        }
        sendOrder({
            id:this.id,
            express_company:this.express_company,
            express_code:this.express_code,
            addr_id:this.addr_id
        }).then((data) => {
          if (data.status == 200) {
            this.$router.go(-1);
          }
        })
    },
}
}
</script>
  
<style scoped>
.address1 {
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  line-height: 20px;
  background: #f8f8f8;
  border-radius: 4px;
  border: 1px dashed #aaaaaa;
  padding: 9px 16px;
}

::v-deep .van-nav-bar__left {
  width: 24px;
  height: 44px;
  background: url("@/assets/left_arrow.png") no-repeat center center;
  background-size: 24px 24px;
}

.bottom_arrow {
  position: absolute;
  width: 18px;
  height: 18px;
  right: 16px;
  top: 7px;
}

input[type="text"] {
  border: none;
  border-radius: 4px;
  padding: 0 16px;
  color: 333;
  font-size: 14px;
}

input[type="text"]::-webkit-input-placeholder {
  font-size: 14px;
  font-weight: 400;
  color: #aaaaaa;
}

.title2 {
  font-size: 14px;
  color: #333333;
  line-height: 32px;
  width: 72px;
}

.btn_out {
  position: fixed;
  width: 100%;
  bottom: 20px;
  height: 40px;
  padding: 0 16px;
  box-sizing: border-box;
}

.btn {
  height: 40px;
  background: #333333;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
  letter-spacing: 2px;
}

#wuliu {
  height: 100%;
  background: url("@/assets/bg.png") repeat-y center top;
  background-size: 100% 100%;
}
</style>
  