<template>
    <div>
        <van-sticky>
            <van-nav-bar :title="title" left-arrow @click-left="onClickLeft" :border="false" :safe-area-inset-top="true" />
        </van-sticky>
        <div class="box">
          <div class="content" v-html="msg"></div>
        </div>
    </div>
</template>
  
<script>
import { other } from '../api'
export default {
    components: {},
    data() {
        return {
           msg:'',
           title:''
        }
    },
    methods: {
        initData() {
            other({
                id:this.$route.params.type
            }).then((data) => {
                if (data.status == 200) {
                    this.msg= data.data.content
                }
            })
        },
        onClickLeft() {
            this.$router.go(-1)
        },
    },
    mounted() { 
        if(this.$route.params.type==2){
            this.title="用户协议";
        }else{
            this.title="隐私协议";
        }
        this.initData();
    },
}
</script>
<style scoped>
 ::v-deep .van-nav-bar__left {
    width: 24px;
    height: 44px;
    background: url("@/assets/left_arrow.png") no-repeat center center;
    background-size: 24px 24px;
  }
.box{
    padding: 16px;
    box-sizing: border-box;
}
.title{
    font-size: 16px;
    font-weight: 400;
    color: #333333;
    margin-bottom: 10px;
}
.time{
    font-size: 14px;
    font-weight: 400;
    color: #777777;
    margin-bottom:6px;
}
</style>
  